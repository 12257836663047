<template>
  <div class="emergency-monitor-box">
    <el-dialog
      :visible.sync="emergencyMonitorCloseVal"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div class="emergency-monitor-body">
        <div class="head-box">Close Exception</div>
        <div class="processor">Processor: {{ personageInfoClose.name }}</div>
        <div class="processing-statement">Processing statement</div>
        <div class="processing-statement-content">
          <textarea
            v-model="processingStatementVal"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="btn-box">
          <el-button size="mini" @click="comfirmEvent" class="black-button"
            >Comfirm
          </el-button>
          <el-button size="mini" @click="closePopup" class="white-button"
            >Cancel
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { casesCloses } from '@/api/tracking/emergencyMonitor'

export default {
  components: {},
  props: [
    'emergencyMonitorCloseVal',
    'emergencyMonitorCloseData',
    'personageInfoClose'
  ],
  data() {
    return {
      processingStatementVal: ''
    }
  },
  filters: {
    caseFormatting(e) {
      if (!e) return ''
      return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
    }
  },
  created() {},
  mounted() {},
  methods: {
    comfirmEvent() {
      const params = {
        statement: this.processingStatementVal
      }
      casesCloses(this.emergencyMonitorCloseData.id,params).then((r) => {
        this.processingStatementVal = ''
        this.$emit('emergencyMonitorCloseEvent', false, 'refurbish')
      })
    },
    closePopup() {
      this.processingStatementVal = ''
      this.$emit('emergencyMonitorCloseEvent', false)
    },
    handleClose(done) {
      this.$emit('emergencyMonitorCloseEvent', false)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog--center {
  width: 506px !important;
  //   height: 772px !important;
  background: #ffffff;
  margin-top: 120px !important;
  border-radius: 12px;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: -35px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 30px;

  i {
    color: white !important;
  }
}

.emergency-monitor-body {
  .head-box {
    font-family: SFPro, SFPro;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    text-align: left;
    font-style: normal;
    margin-top: -40px;
  }

  .processor {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #9c9c9c;
    margin-top: 10px;
    text-align: left;
    font-style: normal;
  }

  .processing-statement {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #9c9c9c;
    margin-top: 10px;
    text-align: left;
    font-style: normal;
  }

  .processing-statement-content {
    width: 100%;
    height: 180px;
    border: 1px solid;
    margin-top: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    textarea {
      width: 95%;
      height: 160px;
      border: none;
      resize: none;
      outline: none;
    }
  }

  button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .black-button {
    // width: 53px !important;
    height: 30px !important;
    background-color: black !important;
    color: white !important;
    border: none !important;
  }

  .white-button {
    // width: 53px !important;
    height: 30px !important;
    background-color: white !important;
    color: black !important;
  }

  .white-button:hover,
  .white-button:focus,
  .white-button:active {
    border: 1px solid #dddddd !important;
  }

  .btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}
</style>
