<template>
  <div class="out_box">
    <div class="justify">
      <div class="title_layout">
        <img src="@/assets/img/icon_back.png" class="img_view" />
        <div class="title">Drivers/</div>
        <div class="title_one">Hashim Briscam</div>
      </div>

      <div class="line"></div>

      <div class="item_view">
        <div class="item_view_left">
          <div class="text_view">Employment info</div>
          <div class="item_left_bg">
            <img src="@/assets/img/icon_advance.png" class="img_view_head" />
            <div class="item_left">
              <div class="text_12">Name</div>
              <div class="text_12_black">Hashim Briscam</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Gender</div>
              <div class="text_12_black">Male</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Company ID</div>
              <div class="text_12_black">9823123465</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Driving years</div>
              <div class="text_12_black">21</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Religion</div>
              <div class="text_12_black">Islam</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">IC NO.</div>
              <div class="text_12_black">70123-12-42333</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">License</div>
              <div class="text_12_black">701232333</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Trips</div>
              <div class="text_12_black">123(O:80)</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">Phone number</div>
              <div class="text_12_black">+60 12345678</div>
            </div>
            <div class="item_left" style="margin-top: 10px">
              <div class="text_12">ECI</div>
              <div class="text_12_black">+60 57123636</div>
            </div>
          </div>
          <div class="text_view">Associated car info</div>
          <div class="item_left_bg_one">
            <div class="item_car_view">
              <img
                src="../../../assets/img/icon_speeding.png"
                class="icon-custom"
              />
              <div style="margin-left: 5px">
                <div class="text_12_blue">LINER1234</div>
                <div class="text_12_black_5">Toyota Vellfire 2.4t</div>
              </div>
            </div>
            <div class="item_car_view">
              <img
                src="../../../assets/img/icon_speeding.png"
                class="icon-custom"
              />
              <div style="margin-left: 5px">
                <div style="display: flex">
                  <div class="text_12_blue">Hashim</div>
                  <div class="car_co_view">Co.</div>
                </div>
                <div class="text_12_black_5">Toyota Vellfire 2.4tVellfire</div>
              </div>
            </div>
            <div
              class="line"
              style="
                width: 90%;
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                border-bottom: 1px solid #ededed;
              "
            ></div>
            <div class="text_view" style="margin-left: 16px">Co jp</div>
            <div>
              <div class="item_car_view">
                <div class="text_12_blue">Hashim Briscam</div>
                <div class="car_co_view">Co.</div>
              </div>
              <div
                class="text_12_blue"
                style="margin-left: 16px; margin-top: 10px"
              >
                Johnny Johnson
              </div>
              <div
                class="text_12_blue"
                style="margin-left: 16px; margin-top: 10px"
              >
                Mark Eiton
              </div>
            </div>
          </div>
        </div>
        <div class="item_view_right">
          <div class="text_view">Performance</div>
          <div class="item_left">
            <div class="item_right_bg">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Driving score
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="text_green" style="font-size: 28px; margin-top: 16px">
                85.6
              </div>
              <div class="text_green" style="font-size: 12px; margin-top: 5px">
                Wise
              </div>
            </div>
            <div class="item_right_bg_one">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">Trips</div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24">5</div>
                  <div class="text_12">total</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">1</div>
                  <div class="text_12">Inbound</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">0</div>
                  <div class="text_12">Outbound</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item_left">
            <div class="item_right_bg">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Fine record
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Fine</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Amount of fine</div>
                </div>
              </div>
            </div>
            <div class="item_right_bg_one">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Complaint record
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Complaint</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item_left">
            <div class="item_right_bg">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Demerit record
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Demerit</div>
                </div>
              </div>
            </div>
            <div class="item_right_bg_one">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Block/unblock record
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Block</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Unblock</div>
                </div>
              </div>
            </div>
          </div>

          <div class="text_view">Finance</div>
          <div class="item_left">
            <div class="item_right_bg">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">
                  Today’s earning
                </div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Total</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Tips</div>
                </div>
              </div>
            </div>
            <div class="item_right_bg_one">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">Claim</div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">To claim</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Claimed</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item_left">
            <div class="item_right_bg">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">Contract</div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_16">18/02/2023</div>
                  <div class="text_12">Contract date</div>
                </div>
                <div class="text_view_center">
                  <div class="text_16">18/02/2024</div>
                  <div class="text_12">End date</div>
                </div>
              </div>
            </div>
            <div class="item_right_bg_one">
              <div class="title_layout">
                <div class="text_view" style="margin-left: 16px">Rental</div>
                <img src="@/assets/img/icon_advance.png" class="img_view_tow" />
              </div>
              <div class="item_left">
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">To pay</div>
                </div>
                <div class="text_view_center">
                  <div class="text_24_gray">-</div>
                  <div class="text_12">Payed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.item_car_view {
  width: 100%;
  display: flex; /* 设置容器为flex布局 */
  align-items: center; /* 垂直居中对齐子元素 */
  margin-left: 16px;
  margin-top: 5px;
  flex: 1;
}

.item_left {
  display: flex; /* 设置容器为flex布局 */
  justify-content: left; /* 水平居中对齐子元素 */
  align-items: center; /* 垂直居中对齐子元素 */
}

.text_view_center {
  text-align: left;
  justify-content: center; /* 水平居中对齐子元素 */
  flex: 1;
}

.text_24 {
  margin-left: 15px;
  font-family: PingFangSC, PingFang SC;
  font-size: 28px;
  color: #000000;
  line-height: 40px;
  font-style: normal;
}

.text_16 {
  margin-left: 15px;
  font-family: PingFangSC, PingFang SC;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  font-style: normal;
}

.text_24_gray {
  margin-left: 15px;
  font-family: PingFangSC, PingFang SC;
  font-size: 28px;
  color: #a2a2a2;
  line-height: 40px;
  font-style: normal;
}

.text_12 {
  margin-left: 15px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #a2a2a2;
  line-height: 17px;
  font-style: normal;
}

.text_12_black {
  margin-left: 15px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 17px;
  font-style: normal;
}

.text_12_blue {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #5187e7;
  line-height: 17px;
  font-style: normal;
}

.text_12_black_5 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 17px;
  font-style: normal;
}

.text_view {
  width: auto;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-top: 10px;
}

.text_green {
  width: auto;
  height: auto;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #5abb6e;
  margin-left: 16px;
  text-align: left;
  font-style: normal;
}

.car_co_view {
  width: 32px;
  height: auto;
  background: rgba(90, 187, 110, 0.1);
  border-radius: 40px;
  margin-left: 10%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  color: #5abb6e;
  border: 1px solid #5abb6e;
}

.item_view {
  width: 100%;
  height: auto;
  display: flex; /* 设置容器为flex布局 */
  justify-content: left; /* 水平居左对齐子元素 */
  .item_view_left {
    width: 300px;
    height: 100%;
    margin-top: 10px;

    .item_left_bg {
      width: 282px;
      height: 389px;
      background: #f2f2f2;
      margin-top: 10px;
      border-radius: 8px;
    }

    .item_left_bg_one {
      width: 282px;
      height: 249px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 10px;
      border: 1px solid #e0e0e0;
    }
  }

  .item_view_right {
    flex: 1;
    height: 100%;
    margin-top: 10px;

    .item_right_bg {
      flex: 1;
      width: 286px;
      height: 113px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 10px;
      border: 1px solid #e0e0e0;
    }

    .item_right_bg_one {
      flex: 1;
      width: 286px;
      height: 113px;
      background: #ffffff;
      border-radius: 8px;
      margin-left: 20px;
      margin-top: 10px;
      border: 1px solid #e0e0e0;
    }
  }
}

.line {
  width: 100%;
  margin-top: 16px;
  border-bottom: 1px solid #ededed;
}

.title_layout {
  width: 100%;
  height: 32px;
  display: flex; /* 设置容器为flex布局 */
  justify-content: left; /* 水平居中对齐子元素 */
  align-items: center; /* 垂直居中对齐子元素 */
  .img_view {
    width: 30px;
    height: 30px;
  }

  .img_view_tow {
    width: 14px;
    height: 14px;
    margin-top: 12px;
  }

  .img_view_head {
    width: 80px;
    height: 98px;
    margin-top: 12px;
  }

  .title {
    width: auto;
    height: 23px;
    font-size: 20px;
    color: #989898;
    align-items: center;
  }

  .title_one {
    width: auto;
    height: 23px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    align-items: center;
  }
}

.justify {
  margin-top: 12px;
  box-sizing: border-box;
  /* width: 756px; */
  padding: 18px 18px 15px 18px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
}

.header-box {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  .header-select-time {
    width: 102px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #eaeaea;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  .header-time {
    width: 97px;
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
}
</style>
