<template>
  <div class="out_box">
    <div class="box-con">
      <div class="header">
        <div class="h-tit">Order list</div>
        <div class="input-search-box">
          <div class="search-input">
            <el-input
              type="text"
              class="input-search"
              size="large"
              placeholder="Search Order No"
              clearable
              show-clear
              v-model="searchValOrderNo"
              @keydown.native.enter="handleEnter"
            />
            <img
              src="../../../assets/img/icon_search.png"
              alt=""
              class="search-icon"
            />
            <div
              class="search-order-list"
              v-if="searchOrderData.length>0"
            >
              <div
                v-for="item in searchOrderData"
                class="list-box"
                :key="item.id"
                @click="searchOrderDetailEvent(item)"
              >
                <div>{{item.no}}</div>
                <div>{{item.trip.vehicleType?item.trip.vehicleType.name:'-'}}</div>
              </div>
            </div>
          </div>
          <div
            class="search-btn"
            @click="searchOrderEvent()"
          >Search</div>
        </div>

      </div>

      <div class="nav-box">
        <div class="nav-list">
          <div
            :class="{ 'is-active': state == 'IN_PROGRESS' }"
            @click="handelTripState('IN_PROGRESS')"
          >
            In-progress
          </div>
          <div
            :class="{ 'is-active': state == 'TO_PICKUP' }"
            @click="handelTripState('TO_PICKUP')"
          >
            To pick-up
          </div>
          <div
            :class="{ 'is-active': state == 'COMPLETED' }"
            @click="handelTripState('COMPLETED')"
          >
            Completed
          </div>
          <div
            :class="{ 'is-active': state == 'CANCELED' }"
            @click="handelTripState('CANCELED')"
          >
            Canceled
          </div>
        </div>
      </div>

      <div class="w100 h40px car-list d_flex">
        <div class="d_flex d_between flex1">
          <date-picker @dateChange="dateChange" />
        </div>

        <div class="car-list-con">
          <div class="car-item">
            <div
              :class="{ 'is-active2': carIndex == index }"
              v-for="(item, index) in carTypeList"
              :key="item.id"
              @click="handelCarType(item.id, index)"
            >
              {{ item ? item.name : '' }}
            </div>
          </div>

          <div class="select-box">
            <el-checkbox-group
              v-model="checkList"
              @change="handelCheckList"
            >
              <el-checkbox
                label="Inbound"
                checked
              />
              <el-checkbox
                label="Outbound"
                checked
              />
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>

    <div class="outer-table-box">
      <el-table
        :data="tableData"
        height="calc(100vh - 288px)"
        style="width: 100%"
        fit
      >
        <el-table-column
          label="No."
          width="50"
        >
          <template slot-scope="scope">
            <div>{{ scope.$index + 1 }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="no"
          label="Order no."
          width="180"
        >
          <template slot-scope="scope">
            <div
              class="t_underline"
              style="color: #5187e7"
              @click="onOrderDetail(scope.row)"
            >
              {{ scope.row.no ? scope.row.no.slice(0, -2) : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="vehicle.licensePlate"
          label="Car plate"
          width="100"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.vehicle ? scope.row.vehicle.licensePlate : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="vehicleType.name"
          label="Type"
          width="70"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.vehicleType ? scope.row.vehicleType.name : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="vehicle.model"
          label="Vehicle"
          width="90"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.vehicle ? scope.row.vehicle.model : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="driverName"
          label="Current driver"
          width="120"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.driverName ? scope.row.driverName : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="originLocation"
          label="Pick-up point"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.originLocation.address }}</div>
            <div style="font-size: 10px; color: #adadad; line-height: 10px">
              Pick-up time:
              {{ scope.row.departureTime ? scope.row.departureTime : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="destinationLocation"
          label="Destination"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.destinationLocation.address }}</div>
            <div style="font-size: 10px; color: #adadad; line-height: 10px">
              Drop-off time:
              {{ scope.row.completeTime ? scope.row.completeTime : '-' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="paging-box">
      <div class="totality">{{ total }} entries in total</div>
      <el-pagination
        align="center"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- ORDERDETAIL -->
    <order-detail
      ref="orderDetail"
      :dialogVisible="orderDataVisible"
    />
  </div>
</template>

<script>
import datePicker from '@/components/datePicker.vue'
import orderDetail from '@/views/home/trips/orderDetail.vue'
import { getCarTypes, tripList, getOrderList } from '@/api/trips/tripList'

export default {
  components: {
    datePicker,
    orderDetail
  },
  data() {
    return {
      searchOrderData: [], //搜索的订单列表
      searchValOrderNo: '',
      startDate: {},
      endDate: {},
      state: 'IN_PROGRESS',
      vehicleTypeId: '64e9c4deab07fb098490d091',
      carIndex: 0,
      checkList: [],
      checkType: '',
      carTypeList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      orderDataVisible: false
    }
  },
  watch: {
    searchValOrderNo(e) {
      if (e.length >= 0) {
        this.searchOrderData = ''
      }
    }
  },
  filters: {},
  created() { },
  mounted() {
    const curDate = this.getCurDate()
    this.startDate = curDate
    this.endDate = curDate
    this.getCarTypesEvent()
    this.orderListEvent()
  },
  methods: {
    searchOrderEvent() {
      let params = {
        no: this.searchValOrderNo,
        size: 10
      }
      getOrderList(params).then((res) => {
        this.searchOrderData = res.data.results
        if (res.data.results.length <= 0) {
          this.$message('The order was not found!');
        }
      })
    },
    getCurDate() {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const currentDate = `${year}-${month}-${day}`
      return currentDate
    },
    handleEnter(event) {
      if (event.keyCode === 13) {
        this.orderListEvent()
      }
    },
    dateChange(e) {
      console.log('dateChange:', e)
      this.startDate = e
      this.endDate = e
      this.searchValOrderNo = undefined
      this.orderListEvent()
    },
    searchOrderDetailEvent(e) {
      this.orderDataVisible = true
      this.$refs.orderDetail.show(e.id)
      this.searchOrderData = ''
      this.searchValOrderNo = ''
    },
    onOrderDetail(row) {
      console.log('onOrderDetail row:', row)
      this.orderDataVisible = true
      this.$refs.orderDetail.show(row.orderId)
    },
    getCarTypesEvent() {
      this.carTypeList = JSON.parse(localStorage.getItem('carTypes'))
      getCarTypes().then((res) => {
        this.carTypeList = res.data.results
        localStorage.setItem('carTypes', JSON.stringify(res.data.results))
      })
    },
    orderListEvent() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        number: this.currentPage,
        size: this.pageSize,
        vehicleTypeId: this.vehicleTypeId
          ? this.vehicleTypeId
          : '64e9c4deab07fb098490d091'
      }
      // if (this.searchValOrderNo) {
      //   params['no'] = this.searchValOrderNo
      // }
      if (this.checkType) {
        params['type'] = this.checkType
      }
      if (this.state === 'TO_PICKUP') {
        params['tag'] = 'INCOMING'
      } else {
        params['state'] = this.state === 'IN_PROGRESS' ? 'ONGOING' : this.state
      }
      // console.log('tripList params:', params)
      tripList(params).then((res) => {
        this.tableData = res.data.results
        this.tableData = this.tableData.map((item) => {
          const newItem = { ...item }
          if (newItem.driver) {
            newItem.driverName = newItem.driver.name
          }
          return newItem
        })
        console.log('tripList tableData:', this.tableData)
        this.total = res.data.totalElements
      })
    },
    handelTripState(val) {
      this.state = val
      this.orderListEvent()
    },
    handelCarType(id, idx) {
      this.carIndex = idx
      this.vehicleTypeId = id
      this.orderListEvent()
    },
    handelCheckList(val) {
      if (val.length == 2) {
        this.checkType = ''
      } else if (val.length == 1) {
        this.checkType = val[0].toUpperCase()
      }
      this.orderListEvent()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.orderListEvent()
    }
  }
}
</script>

<style scoped lang="scss">
.box-con {
  margin-top: 12px;
  height: 148.5px;
  box-sizing: border-box;
  /* width: 756px; */
  padding: 18px 18px 15px 18px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;

  .header {
    display: flex;
    justify-content: space-between;

    ::v-deep .el-input__suffix {
      position: absolute;
      top: -4px;
    }

    .h-tit {
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }
    .input-search-box {
      position: relative;
      display: flex;
      .search-input {
        position: relative;
        .search-order-list {
          position: absolute;
          width: 225px;
          height: 175px;
          overflow: auto;
          background: white;
          border-radius: 6px;
          z-index: 9;
          top: 38px;
          box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05),
            /* 右下方阴影 */ -3px -3px 3px rgba(0, 0, 0, 0.05),
            /* 左上方阴影 */ 3px -3px 3px rgba(0, 0, 0, 0.05),
            /* 右上方阴影 */ -3px 3px 3px rgba(0, 0, 0, 0.05); /* 左下方阴影 */
          scrollbar-width: none !important;
          .list-box {
            width: 201px;
            height: 35px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            justify-content: space-between;
            cursor: pointer;
            div:nth-child(1) {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              font-style: normal;
            }
            div:nth-child(2) {
              padding: 0 5px;
              height: 18px;
              background: #eef0f3;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 10px;
              color: #959698;
              font-style: normal;
            }
          }
        }
      }
      .search-btn {
        width: 64px;
        height: 32px;
        background: #ffc649;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .input-search {
      width: 225px;
      height: 32px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    /* 隐藏 el-input 边框 */
    ::v-deep .el-input__inner {
      border: none !important;
      box-shadow: none !important;
      text-indent: 14px !important;
    }

    /* 获取焦点时不显示边框颜色 */
    ::v-deep .el-input__inner:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .search-icon {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 17px;
      height: 16px;
      cursor: pointer;
    }
  }

  .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ededed;

    .nav-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-weight: 400;

      div {
        margin-right: 40px;
      }

      div:last-child {
        margin-right: 0;
      }

      .is-active {
        padding-bottom: 6px;
        font-weight: 600;
        border-bottom: 3px solid #ffc649;
      }
    }
  }

  .car-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    cursor: pointer;
  }

  .car-list-con {
    display: flex;
  }

  .car-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: 400;

    div {
      height: 32px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      color: #000;
      text-align: center;
      background: #fff;
      border-radius: 16px;
      padding: 0 18px;
      border: 1px solid #dddddd;
    }

    // div:last-child {
    //   margin-right: 0;
    // }
    .is-active2 {
      background: rgb(255 198 73 / 30%);
      border: 1px solid #ffc649;
    }
  }

  .select-box {
    display: flex;
    align-items: center;
    margin-left: 15px;

    .selected {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 4px 0 14px;
      vertical-align: middle;
    }

    ::v-deep span {
      vertical-align: middle;
      // font-weight: 300;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 12px !important;
      color: #000000;
      text-align: center;
      font-style: normal;
    }

    /* 自定义 Element UI 复选框样式 */
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #000;
      background-color: #000;
    }

    ::v-deep .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
      border-color: #dcdfe6;
      background-color: #fff;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
      border-color: #fff;
    }

    ::v-deep .el-checkbox__label {
      color: #000;
    }
  }
}

// 表格
.outer-table-box {
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 14px 14px;
  background: #fff;
  height: calc(100vh - 288px);

  ::v-deep .el-table__header-wrapper {
    font-size: 10px !important;
    border-top: 1px solid #ededed !important;
  }

  ::v-deep .el-table__body-wrapper {
    font-size: 12px !important;
  }

  ::v-deep .el-table__body td {
    padding: 9.9px 0 !important;
    color: black !important;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #fcfcfc !important;
    color: #000 !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #eaeaea !important; /* 添加边框 */
  }

  // page-sizes选择器
}

.paging-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  height: 34px;

  .totality {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #a0a0a0;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  ::v-deep .el-pagination {
  }

  ::v-deep .el-pager li,
  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    border: 1px solid #eaeaea !important;
    background: #fff !important;
    border-radius: 4px;
    min-width: 28px !important;
    padding: 0px !important;
    margin-left: 4px;
  }

  ::v-deep .el-pager .active {
    text-decoration-line: underline !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent !important;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    color: #5187e7;
    text-align: center;
    font-style: normal;
    background: #fff !important;
  }
}
</style>
