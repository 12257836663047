<template>
  <div class="out_box">
    <div class="header-box">
      <div class="header-time">Jan 16, 2024</div>
      <div class="header-select-time container">
        Select Date <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="justify">
      <div class="item-view">测试</div>
      <div class="width">
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_speeding.png"
              class="icon-custom"
            />
            <div>Speeding</div>
          </div>
          <div class="across">
            <div class="body-size">214</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+11%</div>
          </div>
        </div>
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_direction.png"
              class="icon-custom"
            />
            <div>Turning</div>
          </div>
          <div class="across">
            <div class="body-size">214</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+11%</div>
          </div>
        </div>
      </div>
      <div class="width">
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_braking.png"
              class="icon-custom"
            />
            <div>Braking</div>
          </div>
          <div class="across">
            <div class="body-size">214</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+2%</div>
          </div>
        </div>
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_distracted.png"
              class="icon-custom"
            />
            <div>Distracted</div>
          </div>
          <div class="across">
            <div class="body-size">214</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+15%</div>
          </div>
        </div>
      </div>
      <div class="width">
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_acceration.png"
              class="icon-custom"
            />
            <div>Acceration</div>
          </div>
          <div class="across">
            <div class="body-size">214</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+6%</div>
          </div>
        </div>
        <div class="item-view-tow">
          <div class="across">
            <img
              src="../../../assets/img/icon_disconnect.png"
              class="icon-custom"
            />
            <div>Disconnect</div>
          </div>
          <div class="across">
            <div class="body-size">21</div>
            <img src="../../../assets/img/icon_up.png" class="img-up" />
            <div class="body-percentage">+9%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="justify">
      <div class="item-view-three">
        <div class="body-item">
          <div class="body-text">Wise</div>
          <div class="body-text-one">12,200 km / 88 hours</div>
        </div>
        <div class="body-item-tow">
          <div class="justify-tow">
            <div>
              <div class="body-text-24">99.99</div>
              <div class="body-text-14">Avg. Score</div>
            </div>
            <div>
              <img src="../../../assets/img/icon_speeding.png" />
            </div>
          </div>

          <div>
            <div class="line"></div>
          </div>

          <div class="justify-tows">
            <div class="body-center">
              <div class="body-text-24">99</div>
              <div class="body-text-14">Drivers</div>
            </div>
            <div class="body-center">
              <div class="body-text-24">99</div>
              <div class="body-text-14">Coached</div>
            </div>
            <div class="body-center" style="margin-right: 10px">
              <div class="body-text-24">99</div>
              <div class="body-text-14">Events</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-view-three">测试</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  filters: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.header-box {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  .header-select-time {
    width: 102px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #eaeaea;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  .header-time {
    width: 97px;
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
}

.justify {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.justify-tow {
  display: flex;
  width: 38%;
  justify-content: space-between;
}

.justify-tows {
  display: flex;
  width: 50%;
  margin-right: 10%;
  justify-content: space-between;
}

.width {
  width: 218px;
}

.item-view {
  width: 218px;
  height: 338px;
  background: #ffffff;
  border-radius: 8px;
}

.item-view-tow {
  width: 218px;
  height: 164px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 15px;
  overflow: hidden;

  .across {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .icon-custom {
    width: 30px;
    height: 30px;
    background: #e9e9e9;
    border-radius: 50%;
    padding: 5px;
    margin-right: 15px;
    margin-left: 8px;
  }

  .body-size {
    width: 100px;
    height: 80px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 60px;
    color: #000000;
    line-height: 84px;
    margin-left: 16px;

    text-align: left;
    font-style: normal;
  }

  .img-up {
    margin-top: 20px;
    margin-left: 6px;
    width: 18px;
    height: 18px;
  }

  .body-percentage {
    margin-top: 20px;
    width: 32px;
    height: 14px;
    font-family: SFPro, SFPro;
    font-weight: 400;
    font-size: 12px;
    color: #200e32;
    line-height: 14px;
    text-align: left;
    font-style: normal;
  }
}

.item-view-three {
  width: 446px;
  height: 162px;
  background: #ffffff;
  border-radius: 8px;

  .body-item {
    display: flex;
    justify-content: space-between;
  }

  .body-item-tow {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .line {
    width: 1px;
    height: 59px;
    background: #ececec;
  }

  .img-center {
    padding-left: 15px;
    border: 15px;
  }

  .body-center {
    align-items: center;
    width: 50px;
    height: 33px;
  }

  .body-text {
    width: 150px;
    height: 33px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    margin-left: 16px;
    margin-top: 15px;
  }

  .body-text-one {
    width: 162px;
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #a5a5a5;
    line-height: 22px;
    text-align: right;
    font-style: normal;
    margin-top: 15px;
    margin-right: 16px;
  }

  .body-text-24 {
    width: 77px;
    height: 45px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 32px;
    color: #000000;
    line-height: 45px;
    text-align: left;
    margin-left: 16px;
    font-style: normal;
    text-align: center;
  }

  .body-text-14 {
    width: 77px;
    height: 45px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #a5a5a5;
    text-align: left;
    margin-left: 16px;
    font-style: normal;
    text-align: center;
  }
}
</style>
