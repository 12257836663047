// 工具型方法
//保存  localStorage
function saveLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}
// 获取Token方法
function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}
// 移除token
function removeLocalStorage(key) {
  window.localStorage.removeItem(key);
}

// 输出 出去
export { saveLocalStorage, getLocalStorage, removeLocalStorage };
