import axios from 'axios'
import store from '@/store'
import QS from 'qs'

import { Message } from 'element-ui'
import router from '@/router'
import Vue from 'vue'
import versionConfig from '../../package.json'

/**
 * 定义请求常量
 * TIME_OUT, BASE_URL
 */
export const timeout = 55000 // 请求超时时间

// export const baseURL = 'http://localhost:8080';   // 引入全局url
export const baseURL = '/' // 引入全局url

// 创建axios实例

const instance = axios.create({
  baseURL,
  timeout
})

instance.defaults.headers.post['Content-Type'] = 'application/json'
var that = this
// 封装请求拦截
instance.interceptors.request.use(
  (config) => {
    // console.log("config", config);
    // Vue.prototype.$showLoading({
    //   title: " "
    // });
    // console.log("遮罩",)
    const token = localStorage.getItem('token')
    // console.log("token",token)

    if (token != null) {
      // 如果token不为null, 否则传token给后台
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // console.log('store.state.tokenHeaderClientId;', store.state.userAgentShow);
    // console.log('store.state.tokenHeaderClientId;2222', store.state.clientId);
    config.headers['Client-Id'] = store.state.tokenHeaderClientId
    config.headers["Application-Id"] = store.state.applicationId;
    config.headers["Platform"] = store.state.platform;
    // console.log('versionConfig', versionConfig)
    config.headers['Version'] = versionConfig.versionCode
    config.headers['Version-Name'] = versionConfig.version

    return config
  },
  (error) => {
    console.warn(error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    let { data } = response

    if (data.code === 401) {
      handleExpiredToken()
      return Promise.reject(response.data)
    }
    if (data.code !== 200) {
      return Promise.reject(response.data)
    } else {
      return Promise.resolve(response.data)
    }
  },
  (error) => {
    if (!error || !error.response) {
      handleNetworkError()
      return Promise.reject(error.response.data.message)
    }

    switch (error.response.status) {
      case 401:
      case 403:
        setTimeout(handleExpiredToken, 100)
        break
      case 500:
        handleServerError(error)
        break
      case 502:
        Message.error('Server error')
        break
      default:
        break
    }
    return Promise.reject(error.response.data.message)
  }
)

const handleExpiredToken = () => {
  Message.error('Permission expired')
  localStorage.clear()
  router.push('/login')
  // 防止页面不跳转
  // window.location.reload();
}

const handleNetworkError = () => {
  Message.error('Network anomaly')
}

const handleServerError = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    Message.error(error.response.data.message)
  } else {
    if (error.response && error.response.statusText) {
      Message.error(error.response.statusText)
    }
  }
}

// 是否刷新token标识
let isRefreshing = true

// 刷新token
function refreshToken(response) {
  // 刷新token的函数, 这里需要添加一个函数, 防止重复请求
  if (isRefreshing) {
    // 请求刷新token
    refreshTokenRequest()
  }
  isRefreshing = false
  // 添加重试请求
  const retryOriginalRequest = new Promise((resolve) => {
    // 添加请求函数到Promise函数集合
    addSubscriber(() => {
      let config = response.config
      resolve(http.request(config.url, config.method, config.params))
    })
    return retryOriginalRequest
  })
}

// 刷新token的请求函数
function refreshTokenRequest() {
  const refreshToken = localStorage.getItem('refreshToken')
  let params = {
    refreshToken
  }
  instance
    .post('/login/token/refresh', QS.stringify(params))
    .then((res) => {
      // 修改localStorage与vuex中的token
      store.commit('updateToken', res.data)
      onAccessTokenFetched()
      // 修改刷新标识
      isRefreshing = true
    })
    .catch((error) => {
      // 刷新token失败, 跳转到登录页面
      // console.log('',error);
    })
}

// Promise函数集合
let subscribers = []

function onAccessTokenFetched() {
  subscribers.forEach((callback) => {
    callback()
  })
  subscribers = []
}

// 添加请求函数到Promise函数集合
function addSubscriber(callback) {
  subscribers.push(callback)
}

class http {
  static async request(url, method, params) {
    return await instance.request({
      url: url,
      method: method,
      params: params
    })
  }

  static async get(url, params) {
    return await instance.get(url, { params: params })
  }

  static async post(url, params) {
    return await instance.post(url, params)
  }

  static async put(url, params) {
    return await instance.put(url, params)
  }

  static async delete(url, params) {
    return await instance.delete(url, { params: QS.stringify(params) })
  }

  static async upload(url, params) {
    const formData = new FormData()
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key])
    })
    let config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return await instance.post(url, formData, config)
  }
}

export default http
