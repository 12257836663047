import http from '../http'

// 查询紧急情况和事件
export const getCasesList = (param) => http.get(`/api/incidents/cases`, param)

// 查询紧急情况和事件详情
export const casesDetail = (id, param) =>
  http.get(`/api/incidents/cases/${id}`, param)

// 关闭紧急情况和事件
export const casesCloses = (id, param) =>
  http.put(`/api/incidents/cases/${id}/closes`, param)

// 查询紧急情况和事件类型
export const getCasesTypeList = (id, param) =>
  http.get(`/api/incidents`, param)


// 司机账号-查询-绑定信息
export const driversAccounts = (param) =>
  http.get(`/api/drivers/accounts/bindings/queries`, param)


