import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tokenHeaderClientId: "663af5e7b9221d6b480883f0",
        clientId: "UKbQzss8RWjxKNlrYPzhmA",
        clientSecret: "K0EZ9DPPr1GWTcOI1YvNOMxPSrUu9rK8U3ayeFOyEGQ",
        applicationId: '65e56ea1fa7860a3550e62fd',
        platform: "HTML5"
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
