import http from '../http'
import { MD5 } from 'crypto-js'
import store from '../../store/index'

// 登录
export const login = (param) => http.post(`/api/auth/signin`, param)

// 个人信息储存
export const employeesProfiles = (param) =>
  http.get(`/api/employees/profiles`, param)

// 发送邮件
export const codesSends = (param) =>
  http.post(`/api/mails/messages/codes/sends`, param)

// 校验邮箱
export const codesVerifications = (param) =>
  http.post(`/api/mails/messages/codes/verifications`, param)

// 重置密码
export const passwordsResets = (param) =>
  http.post(`/api/employees/accounts/passwords/resets`, param)

// 员工账号-验证
export const accountVerification = (param) =>
  http.get(`/api/employees/accounts/verifications`, param)