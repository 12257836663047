<template>
  <div class="out_box">
    <!--    <div class="emergency-tracking-header-box">-->
    <!--      &lt;!&ndash;      <div&ndash;&gt;-->
    <!--      &lt;!&ndash;        class="box-data-show"&ndash;&gt;-->
    <!--      &lt;!&ndash;        v-for="itme in 4"&ndash;&gt;-->
    <!--      &lt;!&ndash;        :key="itme"&ndash;&gt;-->
    <!--      &lt;!&ndash;      >&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="open-tickets-title">Open tickets</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="open-tickets-type">OPEN/ALL</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="open-tickets-num">3/4</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash; <div class="box-data-show">2</div>-->
    <!--      <div class="box-data-show">3</div>-->
    <!--      <div class="box-data-show">4</div> &ndash;&gt;-->
    <!--    </div>-->
    <div class="emergency-tracking-title">Emergency tracking</div>
    <div
      class="condition-screen-box"
      style="flex: 1"
    >
      <div class="condition-screen-list">
        <div
          :class="{ 'is-active': carIndex == index }"
          v-for="(item, index) in carTypeList"
          :key="item.id"
          @click="handelCarType(item, index)"
        >
          {{  item.type | formattingSymbol }}

        </div>
      </div>
    </div>
    <div class="aggregate-table-box">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 224px)"
      >
        <el-table-column
          prop="no"
          label="No."
          width="70"
        >
          <template slot-scope="scope">
            <div>{{ scope.$index + 1 }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="source"
          width="auto"
          label="Source"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.source | caseFormatting }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="level"
          label="Level"
          width="auto"
        >
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.level === 'LOW'
                  ? ''
                  : scope.row.level === 'HIGH'
                  ? 'danger'
                  : 'warning'
              "
              size="mini"
            >{{ scope.row.level | caseFormatting }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="auto"
        >
          <template slot-scope="scope">
            <div
              class="table-status"
              v-if="scope.row.state == 'OPEN'"
            >
              <div class="type-open"></div>
              <div>{{ scope.row.state | caseFormatting }}</div>
            </div>
            <div v-else>
              <div class="type-close">
                {{ scope.row.state | caseFormatting }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="Type"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.type | formattingSymbol }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="Time"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.submitTime }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="driver" label="Driver">
          <template slot-scope="scope">
            <div>{{ scope.row.driverName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="customer" label="Customer">
          <template slot-scope="scope">
            <div>{{ scope.row.customerName }}</div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="action"
          label="Action"
          width="auto"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="white-button"
              @click="viewEvents(scope.row.id, 'view')"
            >View
            </el-button>
            <el-button
              v-if="scope.row.state == 'OPEN'"
              size="mini"
              class="black-button"
              @click="viewEvents(scope.row.id, 'close')"
            >Close
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="paging-box">
      <div class="totality">{{ total }} entries in total</div>
      <el-pagination
        align="center"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <emergencyMonitorPopUp
      :emergencyMonitorPopUpVal="emergencyMonitorPopUpShow"
      @emergencyMonitorPopUpEvent="emergencyMonitorPopUpEvent"
      :emergencyMonitorPopUpData="emergencyMonitorData"
    />
    <emergencyMonitorClose
      @emergencyMonitorCloseEvent="emergencyMonitorCloseEvent"
      :emergencyMonitorCloseVal="emergencyMonitorCloseShow"
      :emergencyMonitorCloseData="emergencyMonitorData"
      :personageInfoClose="personageInfo"
    />
  </div>
</template>

<script>
import { getCasesList, casesDetail, driversAccounts, getCasesTypeList } from '@/api/tracking/emergencyMonitor'
import emergencyMonitorPopUp from './emergencyMonitorPopUp.vue'
import emergencyMonitorClose from './emergencyMonitorClose.vue'
import { getLocalStorage } from '@/utils/localStorage'

export default {
  components: {
    emergencyMonitorPopUp,
    emergencyMonitorClose
  },
  data() {
    return {
      personageInfo: '',
      emergencyMonitorData: '',
      emergencyMonitorPopUpShow: false,
      emergencyMonitorCloseShow: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      carIndex: 0,
      checkType: '',
      carTypeList: [],
      tableData: []
    }
  },
  filters: {
    caseFormatting(e) {
      if (!e) return ''
      return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
    },
    formattingSymbol(e) {
      if (!e) return ''
      var data
      switch (e) {
        case 'All':
          data = 'All'
          break
        case 'EMERGENCY_ASSISTANCE':
          data = 'Emerg./Asst.'
          break
        case 'LOST_FOUND':
          data = 'Lost&Found'
          break
        case 'COMPLAINT':
          data = 'Complaint'
          break
        case 'DRIVER_REGISTER_ISSUE':
          data = 'Driver register issue'
          break
      }
      return data
    }
  },
  created() { },
  mounted() {
    this.getCasesListEvent()
    getCasesTypeList().then(res => {
      this.carTypeList = [];
      var seen = {};
      // 过滤重复type
      res.data.results.forEach(function (item) {
        if (!seen.hasOwnProperty(item.type)) {
          seen[item.type] = true;
          this.carTypeList.push(item);
        }
      }, this);
      // 加一个全部筛选对象
      var newObject = {
        name: 'All',
        type: 'All'
      };
      this.carTypeList.unshift(newObject);
    })

  },
  methods: {
    emergencyMonitorPopUpEvent(e) {
      this.emergencyMonitorPopUpShow = e
    },
    emergencyMonitorCloseEvent(e, refurbish) {
      this.emergencyMonitorCloseShow = e
      if (refurbish) {
        this.getCasesListEvent()
      }
    },
    async viewEvents(id, event) {
      var res = await casesDetail(id)
      if (res.data.type === "DRIVER_REGISTER_ISSUE") {
        var data = { citizenIdentity: res.data.citizenIdentity }
        var driverAccount = await driversAccounts(data)
        this.emergencyMonitorData = res.data
        this.emergencyMonitorData.driverAccount = driverAccount.data
      } else {
        this.emergencyMonitorData = res.data
      }
      if (event == 'close') {
        this.emergencyMonitorCloseShow = true
        this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
      } else {
        this.emergencyMonitorPopUpShow = true
      }
    },
    getCasesListEvent() {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      var currentDate = `${year}-${month}-${day}`
      const params = {
        startDate: '2024-02-23',
        endDate: currentDate,
        type: this.checkType,
        number: this.currentPage,
        size: this.pageSize
      }
      getCasesList(params).then((res) => {
        // console.log('-----res----->>', res)
        this.tableData = res.data.results
        this.total = res.data.totalElements
      })
    },
    handelCarType(item, idx) {
      // type等于All时，不传type参数
      this.checkType = item.type==='All'?'':item.type
      this.carIndex = idx
      this.currentPage = 1
      this.getCasesListEvent()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCasesListEvent()
    }
  }
}
</script>

<style scoped lang="scss">
.emergency-tracking-header-box {
  width: 100%;
  height: 122px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .box-data-show {
    width: 23%;
    height: 122px;
    background: #ffffff;
    border-radius: 8px;

    .open-tickets-title {
      height: 22px;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      margin: 12px 0 0 13px;
    }

    .open-tickets-type {
      height: 17px;
      font-weight: 500;
      font-size: 12px;
      color: #999999;
      text-transform: uppercase;
      margin: 10px 0 4px 13px;
    }

    .open-tickets-num {
      width: 80px;
      margin: 0px 0 0px 13px;
      font-weight: 500;
      font-size: 36px;
      color: #000000;
    }
  }
}

.aggregate-box {
  width: 100%;
  height: 122px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;

  .aggregate-main-box {
    overflow: hidden;

    .aggregate-main-title {
      // width: 153px;
      height: 22px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin: 12px 0 0 12px;
    }

    .aggregate-main-quantity-hint {
      height: 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      margin: 8px 0 1px 12px;
    }

    .aggregate-main-quantity {
      height: 50px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #000000;
      line-height: 50px;
      text-align: left;
      font-style: normal;
      margin-left: 12px;
    }
  }
}

.aggregate-box > div {
  width: 213px;
  height: 122px;
  background: #ffffff;
  border-radius: 8px;
}

.emergency-tracking-title {
  width: 156px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
}

.condition-screen-box {
  height: 32px;
  width: 901px;
  display: flex;
  justify-content: space-between;
  margin: 12px 0 10px 0;

  .condition-screen-list > div {
    min-width: 76px;
    padding: 0 12px;
    height: 32px;
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    color: #000;
    text-align: center;
    background: #fff;
    border-radius: 16px;
    border: 1px solid #dddddd;
    cursor: pointer;
  }

  .condition-screen-list {
    display: flex;

    .is-active {
      background: rgb(255 198 73 / 30%);
      border: 1px solid #ffc649;
    }
  }

  .add-new-case {
    width: 148px;
    height: 32px;
    background: #000000;
    border-radius: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 17px;
    text-align: center;
    font-style: normal;
  }
}

.aggregate-table-box {
  height: calc(100vh - 224px); // 根据实际情况调整高度
  overflow: auto; // 添加滚动条
  width: 100%;
  flex: 1;
  // height: 512px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  overflow: hidden;

  .type-close {
    color: #aaaaaa;
  }

  .table-status {
    display: flex;
    align-items: center;

    .type-open {
      width: 4px;
      height: 4px;
      background: #ce3721;
      border-radius: 50%;
      margin-right: 3px;
    }
  }

  ::v-deep .el-table__header-wrapper {
    font-size: 10px !important;
    border-top: 1px solid #ededed !important;
  }

  ::v-deep .el-table__body-wrapper {
    font-size: 12px !important;
  }

  ::v-deep .el-table__body td {
    padding: 9.9px 0 !important;
    color: black !important;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #fcfcfc !important;
    color: #000 !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #eaeaea !important; /* 添加边框 */
  }

  button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .black-button {
    // width: 53px !important;
    height: 30px !important;
    background-color: black !important;
    color: white !important;
    border: none !important;
  }

  .white-button {
    // width: 53px !important;
    height: 30px !important;
    background-color: white !important;
    color: black !important;
  }

  .white-button:hover,
  .white-button:focus,
  .white-button:active {
    border: 1px solid #dddddd !important;
  }
}

.paging-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  height: 34px;

  .totality {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #a0a0a0;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  ::v-deep .el-pagination {
  }

  ::v-deep .el-pager li,
  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    border: 1px solid #eaeaea !important;
    background: #fff !important;
    border-radius: 4px;
    min-width: 28px !important;
    padding: 0px !important;
    margin-left: 4px;
  }

  ::v-deep .el-pager .active {
    text-decoration-line: underline !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent !important;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    color: #5187e7;
    text-align: center;
    font-style: normal;
    background: #fff !important;
  }
}
</style>
