<template>
  <div class="item">
    <div class="head">
      <img src="../../../assets/img/user_img.jpg" class="img" />
    </div>

    <div class="name mgt50 pdl20">{{ detail.name }}</div>
    <div class="email pdl20 mgt10">Account: {{ detail.email }}</div>

    <div class="op pdb10">
      <el-switch
        class="pdl20"
        v-model="switchSatate"
        @change="changeStatus"
        active-color="#47B959"
        inactive-color="#999999"
      >
      </el-switch>
      <div class="d_flex pdr20">
        <el-popconfirm
          confirm-button-text="Confirm"
          cancel-button-text="Cancel"
          icon="el-icon-info"
          icon-color="red"
          title="Are you sure to delete account?"
          @confirm="deleted"
        >
          <el-button slot="reference" type="text">
            <div class="op_delete">
              <img src="../../../assets/img/icon_delete.png" class="img" />
            </div>
          </el-button>
        </el-popconfirm>
        <el-button type="text">
          <div class="op_edit mgl10" @click="edit">
            <img src="../../../assets/img/icon_edit.png" class="img" />
          </div>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteEmployee, updateEmployeeState } from '@/api/employee'

export default {
  props: {
    detail: {}
  },
  data() {
    return {
      switchSatate: false
    }
  },
  watch: {
    detail: {
      handler(newVal, oldVal) {
        if (newVal) {
          const { state } = newVal
          if (state === 'ACTIVE') {
            this.switchSatate = true
          } else {
            this.switchSatate = false
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {
    changeStatus(e) {
      console.log('changeStatus:', e)
      const { id } = this.detail
      const params = {
        state: e === true ? 0 : 1
      }
      updateEmployeeState(id, params)
        .then((res) => {
          console.log('updateState res:', res)
        })
        .catch()
    },
    deleted() {
      const { id } = this.detail
      console.log('delete id:', id)
      deleteEmployee(id)
        .then((res) => {
          console.log('delete res:', res)
          this.$emit('delete')
        })
        .catch()
    },
    edit() {
      this.$emit('edit')
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  width: 280px;
  height: 165px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;

  .head {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    position: absolute;
    top: -40px;
    left: 20px;

    .img {
      width: 80px;
      height: 80px;
    }
  }

  .name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-align: center;
    font-style: normal;
  }

  .email {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-align: center;
    font-style: normal;
  }

  .op {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;

    .op_delete {
      width: 34px;
      padding: 6px 2px;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #e85353;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 16px;
        height: 16px;
      }
    }

    .op_edit {
      width: 34px;
      padding: 6px 2px;
      background: #200e32;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
