<template>
  <div class="emergency-monitor-box">
    <el-dialog
      :visible.sync="emergencyMonitorPopUpVal"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div class="emergency-monitor-body">
        <div class="emergency-monitor-title">
          <div class="emergency-monitor-type">
            <span class="type-name">{{ emergencyMonitorPopUpData.type | formattingSymbol }}</span>
            <!-- <span class="level" v-if="emergencyMonitorPopUpData.level=='LOW'">{{ emergencyMonitorPopUpData.level }}</span>
            <span class="level" v-if="emergencyMonitorPopUpData.level=='MEDIUM'">{{ emergencyMonitorPopUpData.level }}</span>
            <span class="level" v-if="emergencyMonitorPopUpData.level=='HIGH'">{{ emergencyMonitorPopUpData.level }}</span> -->
            <el-tag
              :type=" emergencyMonitorPopUpData.level === 'LOW' ? '' : emergencyMonitorPopUpData.level === 'HIGH' ? 'danger' : 'warning'  "
              size="mini"
            >{{ emergencyMonitorPopUpData.level | caseFormatting }}
            </el-tag>
          </div>
          <div
            v-if="emergencyMonitorPopUpData.state == 'CLOSED'"
            class="emergency-monitor-state-close"
          >
            <span class="span-close"></span>{{ emergencyMonitorPopUpData.state | caseFormatting }}
          </div>
          <div
            v-else
            class="emergency-monitor-state-open"
          >
            <span class="span-open"></span>{{ emergencyMonitorPopUpData.state | caseFormatting }}
          </div>
        </div>
        <div class="submit-time">
          {{ emergencyMonitorPopUpData.submitTime }}
        </div>
        <!-- 司机注册问题投诉 -->
        <template v-if="emergencyMonitorPopUpData.type === 'DRIVER_REGISTER_ISSUE'">
          <div class="customer-driver-info-box">
            <div class="driver-info-box">
              <div class="driver-title">Company ID</div>
              <div class="phone-name">
                <div class="driver-phone">
                  {{ emergencyMonitorPopUpData.citizenIdentity }}
                </div>
              </div>
            </div>
            <div class="driver-info-box">
              <div class="driver-title">Date of birth</div>
              <div class="source-text">{{ emergencyMonitorPopUpData.driverAccount.birthday }}</div>
            </div>
            <div class="driver-info-box">
              <div class="driver-title">License</div>
              <div class="source-text">{{ emergencyMonitorPopUpData.driverAccount.driverLicense }}</div>
            </div>

            <div class="driver-car-info-box">
              <div class="driver-title">Related car</div>
              <div
                class="dirver-car-box"
                v-if="emergencyMonitorPopUpData.driverAccount.vehicles"
              >
                <div
                  class="dirver-car-list"
                  v-for="vehicles in emergencyMonitorPopUpData.driverAccount.vehicles "
                  :key="vehicles.id"
                >
                  <div :class="vehicles.state==='SUSPENDED'?'car-state':'car-state-green'">
                    {{vehicles.state}}
                  </div>
                  <div class="car-no"> {{vehicles.licensePlate}}</div>
                  <div class="car-affiliation"> {{vehicles.role}}</div>
                </div>
              </div>
              <div
                class="dirver-car-box-none"
                v-else
              >
                <img
                  src="@/assets/img/no-related-cars.png"
                  alt=""
                  srcset=""
                >
              </div>
            </div>

            <div
              class="driver-info-box"
              v-if="!emergencyMonitorPopUpData.statement"
            >
              <div class="driver-title">Description</div>
              <div class="source-text">-</div>
            </div>
            <div
              class="statement-box"
              v-else
            >
              <div class="statement-title">Description</div>
              <div class="statement-text">
                {{ emergencyMonitorPopUpData.statement }}
              </div>
            </div>
            <div
              class="result-processing-box"
              v-if="emergencyMonitorPopUpData.state == 'CLOSED'"
            >
              <div class="statement-title">Result of processing</div>
              <div class="result-processing-content">
                <div>
                  Processor:
                  {{ emergencyMonitorPopUpData.handles[0].handlerName }}
                </div>
                <div>
                  Processing time:
                  {{ emergencyMonitorPopUpData.handles[0].handleTime }}
                </div>
                <div v-if="emergencyMonitorPopUpData.handles[0].statement === ''">
                  Processing statement: Done
                </div>
                <div v-else>
                  Processing statement:
                  <div class="processing-statement-text">
                    {{ emergencyMonitorPopUpData.handles[0].statement }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="address-info-box">
            <div class="car-info-box">
              <div class="car-info">
                {{ emergencyMonitorPopUpData.vehicleLicensePlate }}
              </div>
              <div class="car-info">
                {{ emergencyMonitorPopUpData.vehicleTypeName }}
              </div>
            </div>
            <div v-if="emergencyMonitorPopUpData.order">
              <div class="address-time-box">
                <div class="start-address-box">
                  <!-- <div class="car-info">
                {{ emergencyMonitorPopUpData.order.vehicleLicensePlate }}
              </div> -->
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    :content="emergencyMonitorPopUpData.trip.originAddress"
                  >
                    <div
                      slot="reference"
                      class="address-info"
                    >
                      {{ emergencyMonitorPopUpData.trip.originAddress }}
                    </div>
                  </el-popover>
                  <div class="start-time">
                    {{ emergencyMonitorPopUpData.trip.startServiceTime }}
                  </div>
                </div>
                <div class="address-arrows">
                  <img
                    src="../../../assets/img/address_arrows.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="end-address-box">
                  <!-- <div class="car-info">
                {{ emergencyMonitorPopUpData.order.vehicleTypeName }}
              </div> -->
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    :content="emergencyMonitorPopUpData.trip.destinationAddress"
                  >
                    <div
                      slot="reference"
                      class="address-info"
                    >
                      {{ emergencyMonitorPopUpData.trip.destinationAddress }}
                    </div>
                  </el-popover>
                  <div class="start-time">
                    {{ emergencyMonitorPopUpData.trip.endServiceTime }}
                  </div>
                </div>
              </div>
              <div class="ticket-no-price">
                <div>
                  Ticket No. <span>{{ emergencyMonitorPopUpData.order.no }}</span>
                </div>
                <div>
                  Price
                  <span>RM {{ emergencyMonitorPopUpData.order.amount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="customer-driver-info-box">
            <div class="driver-info-box">
              <div class="driver-title">Driver</div>
              <div class="phone-name">
                <div class="driver-name">
                  {{ emergencyMonitorPopUpData.driverName }}
                </div>
                <div class="driver-phone">
                  {{ emergencyMonitorPopUpData.driverContact }}
                </div>
              </div>
            </div>
            <div class="driver-info-box">
              <div class="driver-title">Customer</div>
              <div class="phone-name">
                <div class="driver-name">
                  {{ emergencyMonitorPopUpData.customerName }}
                </div>
                <div class="driver-phone">
                  {{
                  emergencyMonitorPopUpData.customerContact
                    ? emergencyMonitorPopUpData.customerContact
                    : '-'
                }}
                </div>
              </div>
            </div>
            <div class="driver-info-box">
              <div class="driver-title">Source</div>
              <div class="source-text">
                {{ emergencyMonitorPopUpData.source }}
              </div>
            </div>
            <div class="driver-info-box">
              <div class="driver-title">Incident</div>
              <div class="source-text">{{ emergencyMonitorPopUpData.name }}</div>
            </div>
            <div
              class="driver-info-box"
              v-if="!emergencyMonitorPopUpData.statement"
            >
              <div class="driver-title">Statement</div>
              <div class="source-text">-</div>
            </div>
            <div
              class="statement-box"
              v-else
            >
              <div class="statement-title">Statement</div>
              <div class="statement-text">
                {{ emergencyMonitorPopUpData.statement }}
              </div>
            </div>
            <div
              class="result-processing-box"
              v-if="emergencyMonitorPopUpData.state == 'CLOSED'"
            >
              <div class="statement-title">Result of processing</div>
              <div class="result-processing-content">
                <div>
                  Processor:
                  {{ emergencyMonitorPopUpData.handles[0].handlerName }}
                </div>
                <div>
                  Processing time:
                  {{ emergencyMonitorPopUpData.handles[0].handleTime }}
                </div>
                <div v-if="emergencyMonitorPopUpData.handles[0].statement === ''">
                  Processing statement: Done
                </div>
                <div v-else>
                  Processing statement:
                  <div class="processing-statement-text">
                    {{ emergencyMonitorPopUpData.handles[0].statement }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['emergencyMonitorPopUpVal', 'emergencyMonitorPopUpData'],
  data() {
    return {}
  },
  filters: {
    /**格式化 _ 为 / */
    formattingSymbol(e) {
      if (!e) return ''
      var data
      switch (e) {
        case 'EMERGENCY_ASSISTANCE':
          data = 'Emerg./Asst.'
          break
        case 'LOST_FOUND':
          data = 'Lost&Found'
          break
        case 'COMPLAINT':
          data = 'Complaint'
          break
        case 'DRIVER_REGISTER_ISSUE':
          data = 'Driver register issue'
          break
      }
      return data
    },
    /** 格式化大小写 */
    caseFormatting(e) {
      if (!e) return ''
      return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
    }
  },
  created() { },
  mounted() { },
  methods: {
    handleClose(done) {
      this.$emit('emergencyMonitorPopUpEvent', false)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog--center {
  width: 506px !important;
  //   height: 772px !important;
  background: #ffffff;
  margin-top: 20px !important;
  border-radius: 12px;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: -35px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 30px;

  i {
    color: white !important;
  }
}

.emergency-monitor-body {
  .emergency-monitor-title {
    width: 458px;
    height: 29px;
    margin-top: -37px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .emergency-monitor-type {
      display: flex;
      align-items: center;

      .type-name {
        font-family: SFPro, SFPro;
        font-weight: bold;
        font-size: 24px;
        color: #000000;
        line-height: 29px;
        text-align: left;
        font-style: normal;
        margin-right: 6px;
      }

      .level {
        padding: 4px 10px;
        background: #ffe2dd;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #5e1715;
        line-height: 17px;
        text-align: center;
        font-style: normal;
      }
    }

    .emergency-monitor-state-close {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #a5a5a5;
      line-height: 18px;
      font-style: normal;

      .span-close {
        width: 6px;
        height: 6px;
        background: #999;
        display: inline-block;
        border-radius: 50%;
        margin-right: 3px;
      }
    }

    .emergency-monitor-state-open {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #000;
      line-height: 18px;
      font-style: normal;

      .span-open {
        width: 6px;
        height: 6px;
        background: #ce3721;
        display: inline-block;
        border-radius: 50%;
        margin-right: 3px;
      }
    }
  }

  .submit-time {
    // width: 130px;
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #bcbcbc;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 8px 0 16px 0;
  }

  .address-info-box {
    width: 458px;
    // height: 170px;
    background: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px 0;

    .car-info-box {
      width: 417px;
      // height: 20px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .car-info {
        // height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        text-align: left;
        font-style: normal;
        // margin-bottom: 16px;
      }
    }

    .address-time-box {
      width: 417px;
      height: 49px;
      margin: 16px auto;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      justify-content: space-between;

      .address-info {
        width: 150px;
        height: 25px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        text-align: right;
        font-style: normal;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .start-time {
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #9c9c9c;
        text-align: left;
        font-style: normal;
      }

      .start-address-box {
        width: 150px;
        height: 49px;
      }

      .address-arrows {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .end-address-box {
        width: 150px;
        height: 49px;
      }

      .end-address-box > div {
        text-align: right;
      }
    }

    .ticket-no-price {
      width: 418px;
      height: 20px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-align: right;
      font-style: normal;

      span {
        font-weight: 600;
      }
    }
  }

  .customer-driver-info-box {
    width: 457px;
    // height: 400px;
    // border: 1px solid;
    margin: 0 auto;
    .driver-car-info-box {
      height: 130px;
      width: 100%;
      border-bottom: 1px solid #eaeaea;
      .dirver-car-box-none {
        width: 458px;
        height: 82px;
        display: flex;
        background: #f2f3f4;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        img {
          width: 138px;
          height: 48px;
        }
      }
      .dirver-car-box {
        width: 458px;
        height: 82px;
        display: flex;
        overflow: auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .dirver-car-list {
          width: 215px;
          height: 82px;
          background: #f2f3f4;
          border-radius: 12px;
          margin-bottom: 6px;
          .car-state {
            width: 74px;
            height: 16px;
            background: #999999;
            border-radius: 9px;
            font-family: PingFangSC, PingFang SC;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            text-transform: uppercase;
            margin-left: auto;
            margin-top: 6px;
            margin: 6px 6px 4px auto;
          }
          .car-state-green {
            width: 60px;
            height: 16px;
            background: #5abb6e;
            border-radius: 9px;
            font-family: PingFangSC, PingFang SC;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            text-transform: uppercase;
            margin-left: auto;
            margin-top: 6px;
            margin: 6px 6px 4px auto;
          }
          .car-no {
            text-align: center;
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #200e32;
            font-style: normal;
          }
          .car-affiliation {
            text-align: center;
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #a19aa9;
            margin-top: 4px;
            font-style: normal;
          }
        }
      }
      .driver-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        margin-top: 16px;
        margin-bottom: 12px;
        text-align: left;
        font-style: normal;
      }
    }
    .driver-info-box {
      height: 52px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaeaea;

      .source-text {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        text-align: right;
        font-style: normal;
      }

      .driver-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }

      .phone-name {
        display: flex;
        height: 20px;

        .driver-phone {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #5187e7;
          text-align: right;
          font-style: normal;
          height: 20px;
        }

        .driver-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          height: 20px;
          font-size: 14px;
          color: #000000;
          text-align: right;
          font-style: normal;
          padding-right: 8px;
          border-right: 1px solid #eaeaea;
          margin-right: 8px;
        }
      }
    }

    .statement-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      margin: 16px 0 8px 0px;
      font-style: normal;
    }

    .statement-box {
      // height: 165px;
      width: 100%;
      border-bottom: 1px solid #eaeaea;

      .statement-text {
        width: 458px;
        max-height: 120px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #9f9f9f;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-bottom: 10px;
        overflow: auto;
      }
    }

    .result-processing-box {
      width: 100%;

      .result-processing-content {
        width: 458px;
        // height: 60px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #9f9f9f;
        line-height: 20px;
        text-align: left;
        font-style: normal;

        .processing-statement-text {
          width: 458px;
          max-height: 120px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #9f9f9f;
          line-height: 20px;
          margin-top: 10px;
          text-align: left;
          font-style: normal;
          margin-bottom: 10px;
          overflow: auto;
        }
      }
    }
  }
}
</style>
