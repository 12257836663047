<template>
  <div class="products">
    <el-container>
      <el-header class="products-head">
        <headLogin></headLogin>
      </el-header>
      <el-container class="products-left-nav">
        <el-aside style="width: 260px; padding-top: 12px">
          <el-menu
            :unique-opened="true"
            router
            :default-active="$route.path"
            class="el-menu-vertical-demo"
          >
            <el-submenu
              index="0"
              class="parent-submenu"
            >
              <template slot="title"><i class="icon-orders"></i>Orders
              </template>
              <el-menu-item index="/home/trips/tripList">Order list
              </el-menu-item>
            </el-submenu>
            <el-submenu
              index="1"
              class="parent-submenu"
            >
              <template slot="title"><i class="icon-fleet-admin"></i>Fleet admin
              </template>
              <el-menu-item index="/home/fleetadmin/drivers">Drivers
              </el-menu-item>
              <el-menu-item index="/home/fleetadmin/vehicles">Vehicles
              </el-menu-item>
              <el-menu-item index="/home/tracking/emergencyMonitor">Emergency monitor
              </el-menu-item>
            </el-submenu>
            <!-- ADMINISTRATION MENU -->
            <el-submenu
              index="2"
              class="parent-submenu"
            >
              <template slot="title">
                <i class="icon-administration"></i>Administration
              </template>
              <el-menu-item
                index="/home/employee/index"
                :disabled="disabledEmployeeProfile"
              >Employee profile
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main class="layout_body">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getLocalStorage } from '@/utils/localStorage'
import headLogin from '@/components/head.vue'

export default {
  components: {
    headLogin
  },
  data() {
    return {
      disabledEmployeeProfile: true //是否支持编辑员工账号
    }
  },
  created() { },
  mounted() {
    this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
    // console.log('home mounted personageInfo:', this.personageInfo)
    if (this.personageInfo && this.personageInfo.role === 'ROOT') {
      this.disabledEmployeeProfile = false
    } else {
      this.disabledEmployeeProfile = true
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
// 当子菜单被选中时，为父级添加背景色
::v-deep
  .el-menu-vertical-demo
  .el-submenu.parent-submenu.is-opened
  > .el-submenu__title {
  background-color: #f2f2f2 !important; // 设置父级背景色
  border-radius: 8px !important;
}

::v-deep .el-menu-item {
  height: 32px !important;
  width: 234px !important;
  margin: 8px auto 0 auto !important;
  display: flex;
  align-items: center;
}

::v-deep .el-menu-item:last-child {
  margin-bottom: 8px !important;
}

::v-deep .el-submenu__title {
  height: 48px !important;
  width: 234px;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
}

.el-aside {
  background-color: white !important;
}

.products {
  background-color: #f2f2f2;

  .el-main {
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  .el-header {
    padding: 0;
    height: 76px !important;
  }

  .products-left-nav {
    border-radius: 4px;
    /* 选中菜单项的样式 */
    .el-menu-vertical-demo .el-menu-item.is-active {
      color: #3e91f1 !important; /* 设置鼠标悬停时的文本颜色 */
      background-color: transparent !important;
      border-radius: 8px !important;
    }

    .el-menu-vertical-demo .el-menu-item:hover,
    ::v-deep .el-submenu__title:hover {
      background-color: transparent !important; /* 设置鼠标悬停时的背景颜色 */
    }

    .el-menu-vertical-demo {
      width: 260px;
      height: calc(100vh - 88px);

      .el-submenu .el-menu-item {
        width: 260px;
      }
    }

    .icon-orders {
      width: 20px;
      height: 20px;
      background-image: url('@/assets/img/icon_orders.png');
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-left: -10px;
    }

    .icon-risk-safety {
      width: 20px;
      height: 20px;
      background-image: url('@/assets/img/icon_risk_safety.png');
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-left: -10px;
    }

    .icon-fleet-admin {
      width: 20px;
      height: 20px;
      background-image: url('@/assets/img/icon_fleet_admin.png');
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-left: -10px;
    }

    .icon-administration {
      width: 20px;
      height: 20px;
      background-image: url('@/assets/img/icon_administration.png');
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-left: -10px;
      opacity: 0.4;
    }

    .icon-tracking {
      width: 20px;
      height: 20px;
      background-image: url('@/assets/img/icon_tracking.png');
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      margin-left: -10px;
    }
  }

  #submenu {
    color: #000 !important;
  }
}
</style>
