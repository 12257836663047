<template>
  <div class="head-logo">
    <div class="head-top">
      <div class="logo-name">
        <img src="../assets/img/logo.png" class="img-login" alt="ALM" />
      </div>
      <div class="head-name">FLEET MGMT</div>
      <div class="boundary"></div>
    </div>

    <div class="head-user-info">
      <!-- 头部弹窗 -->
      <el-popover placement="bottom" width="auto" trigger="click">
        <div class="user-info-box">
          <div class="user-info-img">
            <img src="../assets/img/user_img.jpg" alt="ALM" />
          </div>
          <div class="user-info-name">{{ personageInfoName }}</div>
          <div class="user-info-settings" @click="userInfoShowEvent">
            Settings
          </div>
          <div class="user-info-signout" @click="toLogin">Sign out</div>
        </div>
        <div class="user-info" slot="reference">
          <div>{{ personageInfoName ? personageInfoName : '' }}</div>
          <div class="user-img mgl10">
            <img src="../assets/img/user_img.jpg" alt="ALM" />
          </div>
        </div>
      </el-popover>
    </div>

    <!-- 个人信息-修改密码 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="userInfoShow"
      width="30%"
      center
    >
      <!-- 修改密码确认弹窗 -->
      <el-dialog
        width="30%"
        style="margin-top: 20vh"
        :close-on-click-modal="false"
        :visible.sync="innerVisible"
        append-to-body
        :show-close="false"
      >
        <div class="secondary-confirmation">
          <div class="secondary-confirmation-text">
            Are you sure you want to cancel? Your changes will not be saved.
          </div>
          <div class="secondary-confirmation-btn">
            <div
              class="secondary-confirmation-btn-no"
              @click="innerVisible = false"
            >
              No
            </div>
            <div class="secondary-confirmation-btn-yes" @click="closeYes">
              Yes
            </div>
          </div>
        </div>
      </el-dialog>

      <div class="user-info-dialog">
        <i @click="closeEvent" class="el-icon-close close-icon"></i>
        <div class="user-info-left">
          <div class="user-name">
            {{ personageInfoName ? personageInfoName : '' }}
          </div>
          <div class="user-account-number">
            {{ personageInfo ? personageInfo.email : '' }}
          </div>
          <div class="my-profile">My profile</div>
        </div>
        <div v-if="myProfileShow" class="user-info-right-synopsis">
          <div class="head-navigation">My profile</div>
          <div class="user-name-lastname">
            <!-- 头像 -->
            <div class="user-head-portrait">
              <img src="../assets/img/user_img.jpg" alt="ALM" />
            </div>
            <div class="user-out-ipt-box">
              <div class="user-name-ipt-box">
                <div class="user-name-ipt">
                  <div class="user-name-ipt-text">Name</div>
                  <el-input
                    v-model="personageInfo.name"
                    :disabled="disabledShow"
                  ></el-input>
                </div>
              </div>
              <div class="user-phone-number">
                <div class="user-phone-ipt">
                  <div class="user-name-ipt-text">Phone number</div>
                  <el-input
                    placeholder=""
                    v-model="personageInfo.mobile"
                    :disabled="disabledShow"
                    class="input-with-select"
                    @focus="onInputFocus"
                    @blur="onInputBlur"
                  >
                    <el-select
                      v-model="personageInfo.nationCode"
                      slot="prepend"
                      :disabled="disabledShow"
                      placeholder=""
                      class="custom-select"
                      :style="
                        disabledShow
                          ? 'background: #f5f7fa;'
                          : 'background: #fff;'
                      "
                    >
                      <el-option
                        v-for="option in countriesListData"
                        :key="option.id"
                        :label="'+ ' + option.phoneCode"
                        :value="option.phoneCode"
                      ></el-option>
                    </el-select>
                  </el-input>
                </div>
              </div>
              <div class="user-password-box" v-if="disabledShow">
                <div class="password-text">Password</div>
                <div class="password-event" @click="updPasswordEvent">
                  Reset password
                </div>
              </div>
              <div v-if="disabledShow" class="btn-box">
                <div class="user-edit" @click="editEvent">Edit</div>
              </div>
              <div v-else class="btn-box">
                <div class="user-cancel" @click="cancelEvent">Cancel</div>
                <div class="user-edit" @click="saveEvent">Save</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="user-info-right-password">
          <div class="head-navigation">Reset Password</div>
          <div class="user-pwd-box">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="Old password" prop="oldPass">
                <el-input
                  :class="
                    OldpwdShow == 2
                      ? 'custom-suffix-icon'
                      : OldpwdShow == 1
                      ? 'custom-suffix-icon-error'
                      : ''
                  "
                  :clearable="false"
                  v-model="ruleForm.oldPass"
                  type="password"
                ></el-input>
              </el-form-item>
              <div class="password-text-tips">
                <div
                  class="password-text-tips-box"
                  v-if="ruleForm.pass.length > 0"
                >
                  minimum 8 digits, must contain numbers and letters, support
                  ,.!_?symbol
                </div>
                <el-form-item label="New password" prop="pass">
                  <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    :clearable="false"
                    show-password
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item label="Confirm new password" prop="checkPass">
                <el-input
                  show-password
                  type="password"
                  :clearable="false"
                  v-model="ruleForm.checkPass"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn-box">
              <div class="user-cancel" @click="cancelPasswordEvent">Cancel</div>
              <div class="user-edit" @click="submitForm">Save</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLocalStorage,
  removeLocalStorage,
  saveLocalStorage
} from '@/utils/localStorage.js'
import {
  countriesList,
  staffProfilesUpd,
  passwordVerify,
  passwordUpd
} from '@/api/head/index'
import { employeesProfiles } from '@/api/login/login'
import { MD5 } from 'crypto-js'
import store from '@/store'

export default {
  props: {},
  data() {
    var checkOldPass = (rule, value, callback) => {
      if (!value) {
        this.OldpwdShow = 1
        this.judgeRuleForm.oldPass = false
        return callback(new Error('Old password Cannot be empty'))
      }
      var params = {
        password: MD5(value).toString()
      }
      passwordVerify(params)
        .then((r) => {
          this.OldpwdShow = 2
          this.judgeRuleForm.oldPass = true
        })
        .catch((err) => {
          this.judgeRuleForm.oldPass = false
          this.OldpwdShow = 1
          return callback(new Error('Wrong password, please try again'))
        })
    }
    var validatePass = (rule, value, callback) => {
      var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d.！_？]{8,}$/
      if (value === '') {
        this.judgeRuleForm.pass = false
        return callback(new Error('Please enter your password'))
      } else if (!passwordRegex.test(value)) {
        this.judgeRuleForm.pass = false
        return callback(
          new Error('Must contain numbers and letters, please change')
        )
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        this.judgeRuleForm.pass = true
        return callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        this.judgeRuleForm.checkPass = false
        return callback(new Error('Please enter your password again'))
      } else if (value !== this.ruleForm.pass) {
        this.judgeRuleForm.checkPass = false
        return callback(new Error('Two passwords do not match!'))
      } else {
        this.judgeRuleForm.checkPass = true
        return callback()
      }
    }
    return {
      OldpwdShow: 0,
      judgeRuleForm: {
        pass: false,
        checkPass: false,
        oldPass: false
      },
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPass: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        oldPass: [{ validator: checkOldPass, trigger: 'blur' }]
      },
      myProfileShow: true, //展示资料简介
      userInfoShow: false, //修改密码弹窗
      disabledShow: true, //文本框禁用
      innerVisible: false, //二次确认框
      countriesListData: '', //国家码
      personageInfo: '', //个人信息
      personageInfoName: '' //个人信息name
    }
  },
  created() {},
  mounted() {
    this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
    this.personageInfoName = JSON.parse(getLocalStorage('personageInfo')).name
  },
  methods: {
    userInfoShowEvent() {
      this.myProfileShow = true
      this.userInfoShow = true
      this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
      this.personageInfoName = JSON.parse(getLocalStorage('personageInfo')).name
      countriesList().then((res) => {
        this.countriesListData = res.data.data.results
      })
    },
    updPasswordEvent() {
      this.myProfileShow = false
    },
    cancelPasswordEvent() {
      if (Object.values(this.ruleForm).every((value) => value === '')) {
        this.myProfileShow = true
        return
      }
      this.innerVisible = true
    },
    submitForm(formName) {
      const allTrue = Object.values(this.judgeRuleForm).every(
        (value) => value === true
      )
      if (allTrue) {
        const params = {
          oldPassword: MD5(this.ruleForm.oldPass).toString(),
          password: MD5(this.ruleForm.pass).toString(),
          confirmPassword: MD5(this.ruleForm.checkPass).toString(),
          clientId: store.state.clientId,
          clientSecret: store.state.clientSecret
        }
        passwordUpd(params).then((res) => {
          this.myProfileShow = true
          this.$message({
            message: 'Password changed successfully',
            type: 'success'
          })
        })
      } else {
        // 存在至少一个值不为 true
        // console.log("存在至少一个值不为 true");
        // this.$refs[formName].validate((valid) => {
        //   console.log("validvalid-----", valid);
        //   if (valid) {
        //     alert("submit!");
        //   } else {
        //     console.log("error submit!!");
        //     return false;
        //   }
        // });
      }
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    cancelEvent() {
      var judgeSame = this.isObjectEqual(
        JSON.parse(getLocalStorage('personageInfo')),
        this.personageInfo
      )
      if (judgeSame) {
        this.disabledShow = true
      } else {
        this.innerVisible = true
      }
    },
    closeYes() {
      this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
      this.innerVisible = false
      this.disabledShow = true
      this.myProfileShow = true
      this.ruleForm = {
        pass: '',
        checkPass: '',
        oldPass: ''
      }
      this.OldpwdShow = 0
    },
    async saveEvent() {
      var judgeSame = this.isObjectEqual(
        JSON.parse(getLocalStorage('personageInfo')),
        this.personageInfo
      )
      if (judgeSame) {
        this.disabledShow = true
      } else {
        const params = {
          name: this.personageInfo.name,
          nationCode: this.personageInfo.nationCode,
          mobile: this.personageInfo.mobile,
          avatar: this.personageInfo.avatar ? this.personageInfo.avatar : ''
        }
        await staffProfilesUpd(params)
        await this.storingUserInfo()
        this.$message({
          message: 'Modify successfully!',
          type: 'success'
        })
        this.disabledShow = true
      }
    },
    storingUserInfo() {
      return new Promise((resolve, reject) => {
        employeesProfiles().then((r) => {
          saveLocalStorage('personageInfo', JSON.stringify(r.data.data))
          this.personageInfo = JSON.parse(getLocalStorage('personageInfo'))
          this.personageInfoName = JSON.parse(
            getLocalStorage('personageInfo')
          ).name
          resolve()
        })
      })
    },
    editEvent() {
      this.disabledShow = false
    },
    toLogin() {
      removeLocalStorage('token')
      removeLocalStorage('personageInfo')
      this.$router.push('/login')
    },
    isObjectEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
    closeEvent() {
      this.userInfoShow = false
    },
    onInputFocus(event) {
      event.target.style.borderColor = '#ffc649'
    },
    onInputBlur(event) {
      event.target.style.borderColor = ''
    }
  }
}
</script>

<style scoped lang="scss">
.user-info-box {
  width: 248px;
  height: 231px;

  .user-info-img {
    width: 68px;
    height: 68px;
    margin: 32px auto 13px auto;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .user-info-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-align: center;
    font-style: normal;
    margin-top: 10px;
  }

  .user-info-settings {
    width: 229px;
    height: 36.5px;
    border-bottom: 1px solid #eaeaea;
    margin: 32px auto 0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-indent: 6px;
    font-style: normal;
    cursor: pointer;
  }

  .user-info-signout {
    width: 229px;
    height: 36.5px;
    margin: 16px auto 0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #e93e25;
    text-indent: 6px;
    font-style: normal;
    cursor: pointer;
  }
}

.head-logo {
  background-color: #ffff;
  display: flex;
  height: 76px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .head-user-info {
    display: flex;
    justify-content: center;
    align-items: center;

    .user-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      text-align: center;
      font-style: normal;

      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .head-top {
    display: flex;
    justify-content: center;
    align-items: center;

    .boundary {
      width: 235px;
      height: 1px;
      background: #eaeaea;
      position: absolute;
      bottom: 0;
      left: 12px;
    }

    .logo-name {
      margin: 0 7px 0 24px;
      height: 24px;

      .img-login {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }

    .head-name {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #000000;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }
  }
}

// 信息框
::v-deep .el-dialog--center {
  width: 898px !important;
  height: 642px !important;
  background: #ffffff;
  margin-top: 120px !important;
  border-radius: 12px;
  // overflow: hidden;
}

// ::v-deep .el-dialog__headerbtn {
//   position: absolute;
//   top: 0px;
//   right: 35px;
//   padding: 0;
//   background: 0 0;
//   border: none;
//   outline: 0;
//   cursor: pointer;
//   font-size: 30px;
//   i {
//     color: white !important;
//   }
// }
::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-dialog__header {
  padding: 0 !important;
}

::v-deep .el-dialog {
  border-radius: 12px !important;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-input-group__prepend {
  border: none;
}

.custom-select .el-input__inner:focus {
  border-color: transparent;
  box-shadow: none;
}

.secondary-confirmation {
  // border: 1px solid;
  width: 455px;
  height: 200px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 auto;

  .secondary-confirmation-text {
    width: 407px;
    height: 44px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin: 30px auto 50px auto;
  }

  .secondary-confirmation-btn {
    width: 407px;
    height: 46px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    .secondary-confirmation-btn-no {
      width: 154px;
      height: 46px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      margin-right: 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      cursor: pointer;
    }

    .secondary-confirmation-btn-yes {
      width: 154px;
      height: 46px;
      background: #000000;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      cursor: pointer;
    }
  }
}

.user-info-dialog {
  width: 898px !important;
  height: 642px !important;
  background: #ffffff;
  display: flex;
  position: relative;
  border-radius: 12px;

  .close-icon {
    font-size: 30px;
    color: white !important;
    position: absolute;
    top: 0px;
    right: -35px;
    cursor: pointer;
  }

  .user-info-left {
    width: 254px;
    height: 642px;
    background: #f5f5f5;
    border-radius: 12px 0px 0px 12px;

    .user-name {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin: 24px 0 2px 32px;
    }

    .user-account-number {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #939393;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      margin: 0 0 27px 32px;
    }

    .my-profile {
      width: 222px;
      height: 44px;
      background: #e5e5e5;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      text-align: left;
      font-style: normal;
      margin: 0 auto;
      text-indent: 16px;
    }
  }

  .user-info-right-synopsis {
    width: 644px;
    height: 642px;
    background: #ffffff;
    border-radius: 0 12px 12px 0;

    .head-navigation {
      height: 35px;
      width: 580px;
      border-bottom: 1px solid #eaeaea;
      margin: 24px auto;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .user-name-lastname {
      width: 580px;
      height: 536px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      .user-head-portrait {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .user-out-ipt-box {
        width: 496px;
        height: 536px;
        position: relative;

        .user-name-ipt-box {
          width: 496px;
          height: 80px;
          display: flex;
          justify-content: space-between;
        }

        .user-name-ipt {
          width: 242px;
          height: 80px;

          .user-name-ipt-text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            margin-bottom: 8px;
            text-align: left;
            font-style: normal;
          }

          ::v-deep .el-input__inner {
            width: 242px !important;
            height: 50px !important;
          }

          ::v-deep .el-input__inner:focus {
            border-color: #ffc649;
          }
        }

        .user-phone-ipt {
          height: 80px;

          ::v-deep .el-select {
            width: 104px !important;
          }

          .custom-select {
            border: 1px solid #dcdfe6;

            box-sizing: border-box;
            height: 50px;
            border-radius: 4px 0 0 4px;
            border-right: none;
          }

          .user-name-ipt-text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            margin-bottom: 8px;
            text-align: left;
            font-style: normal;
          }

          ::v-deep .el-input__inner {
            height: 50px !important;
          }

          .input-with-select:focus {
            border-color: #ffc649 !important;
          }
        }

        .user-phone-number {
          width: 496px;
          height: 80px;
          margin-top: 42px;
        }

        .user-password-box {
          width: 130px;
          height: 70px;
          margin-top: 40px;

          .password-text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            margin-bottom: 12px;
          }

          .password-event {
            width: 130px;
            height: 36px;
            background: #ffffff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid #e3e3e3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            text-align: left;
            font-style: normal;
            cursor: pointer;
          }
        }

        .btn-box {
          position: absolute;
          right: 0px;
          bottom: 0px;
          display: flex;

          .user-edit {
            width: 154px;
            height: 46px;
            background: #000000;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            font-style: normal;
            cursor: pointer;
          }

          .user-cancel {
            width: 154px;
            height: 46px;
            margin-right: 10px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #e3e3e3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: black;
            text-align: center;
            font-style: normal;
            cursor: pointer;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  ::v-deep .el-input__inner {
    height: 50px !important;
  }

  .user-info-right-password {
    width: 644px;
    height: 642px;
    background: #ffffff;
    border-radius: 0 12px 12px 0;

    .head-navigation {
      height: 35px;
      width: 580px;
      border-bottom: 1px solid #eaeaea;
      margin: 24px auto;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .user-pwd-box {
      position: relative;
      height: 535px;
      width: 580px;
      margin: 0 auto;

      .password-text-tips {
        position: relative;

        .password-text-tips-box {
          position: absolute;
          top: 13px;
          left: 119px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #aaaaaa;
          font-style: normal;
        }
      }

      .custom-suffix-icon::after {
        content: '';
        width: 16px; /* 图片宽度 */
        height: 16px; /* 图片高度 */
        background-image: url('../assets/img/correct_icon.png'); /* 本地图片路径 */
        background-size: cover;
        position: absolute;
        right: 10px; /* 根据需要调整图标位置 */
        top: 50%; /* 根据需要调整图标位置 */
        transform: translateY(-50%); /* 垂直居中 */
      }

      .custom-suffix-icon-error::after {
        content: '';
        width: 16px; /* 图片宽度 */
        height: 16px; /* 图片高度 */
        background-image: url('../assets/img/error_icon.png'); /* 本地图片路径 */
        background-size: cover;
        position: absolute;
        right: 10px; /* 根据需要调整图标位置 */
        top: 50%; /* 根据需要调整图标位置 */
        transform: translateY(-50%); /* 垂直居中 */
      }

      ::v-deep .el-form-item__label {
        // margin-top: -10px !important;
        margin-bottom: -8px !important;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        font-style: normal;
      }

      ::v-deep .el-input__inner:focus {
        border-color: #ffc649;
      }

      .user-pwd-ipt-box {
        height: 80px;
        width: 580px;
        border: 1px solid;
        margin-bottom: 42px;

        .user-pwd-ipt-text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          margin-bottom: 8px;
          text-align: left;
          font-style: normal;
        }
      }

      .btn-box {
        position: absolute;
        right: 0px;
        bottom: 0px;
        display: flex;

        .user-edit {
          width: 154px;
          height: 46px;
          background: #000000;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          font-style: normal;
          cursor: pointer;
        }

        .user-cancel {
          width: 154px;
          height: 46px;
          margin-right: 10px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #e3e3e3;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: black;
          text-align: center;
          font-style: normal;
          cursor: pointer;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
