<template>
  <div class="out_box">
    <div class="page_head mgt20">
      <div class="title mgl20">Employee profile</div>
      <div class="btn_add mgr20" @click="showAdd()">
        <img
          class="btn_add_icon"
          src="../../../assets/img/icon_add.png"
          alt=""
          srcset=""
        />
        <div class="mgl4">Add acount</div>
      </div>
    </div>

    <div class="flex_box">
      <div class="gpitem" v-for="(item, i) in tableData" :key="i">
        <employee-item
          :detail="item"
          @edit="showEdit(item)"
          @delete="loadList"
        />
      </div>
      <div
        class="gpitem"
        v-for="item in row - (tableData.length % row)"
        v-if="tableData.length % row > 0"
      ></div>
    </div>

    <employee-new-edit ref="newEdit" @edit="loadList" />
  </div>
</template>

<script>
import employeeItem from '@/views/home/employee/employeeItem.vue'
import employeeNewEdit from '@/views/home/employee/employeeNewEdit.vue'
import { employeeList } from '@/api/employee'

export default {
  components: {
    employeeItem,
    employeeNewEdit
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      totalElements: 0,
      totalPage: 0,
      tableData: [],
      row: 3
    }
  },
  filters: {},
  created() {},
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      console.log('loadList')
      const params = {
        number: this.currentPage,
        size: this.pageSize
      }
      employeeList(params).then((res) => {
        this.tableData = res.data.results
        this.totalElements = res.data.totalElements
        this.totalPage = res.data.totalPage
      })
    },
    showAdd() {
      console.log('showAdd')
      this.$refs.newEdit.show()
    },
    showEdit(data) {
      console.log('showEdit :{}', data)
      this.$refs.newEdit.show(data)
    }
  }
}
</script>

<style scoped lang="scss">
.page_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    font-style: normal;
  }

  .btn_add {
    width: 151px;
    height: 32px;
    background: #200e32;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 17px;
    font-style: normal;

    .btn_add_icon {
      width: 10px;
      height: 10px;
    }
  }
}

.flex_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 50px;

  .gpitem {
    flex: 33.3333333333%;
  }
}
</style>
