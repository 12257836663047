<template>
  <div class="out_box">
    <!-- 日期-->
    <div class="header-box">
      <div class="header-time">Jan 16, 2024</div>
      <!-- <div class="header-select-time container">
          Select Date <i class="el-icon-arrow-right"></i>
      </div> -->
      <el-date-picker
        v-model="value1"
        class="date-down"
        :picker-options="dateButton"
        @change="handleChange"
        type="daterange"
        range-separator="To"
        start-placeholder="StartDate"
        end-placeholder="EndDate"
      >
      </el-date-picker>
    </div>
    <div class="justify">
      <div class="title_layout">
        <img src="@/assets/img/icon_back.png" class="img_view" />
        <div class="title">Hashim Briscam/</div>
        <div class="title_one">trips</div>
      </div>
      <!-- 表格 -->
      <div class="outer-table-box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="name"
            label="Ticket no."
            width="220"
            style="size: 40px"
          >
            <template slot-scope="scope">
              <div style="display: flex">
                <div>{{ scope.row.id }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="Date" style="width: 100%">
            <template slot-scope="scope">
              <div>{{ scope.row.gender }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="segment" label="Car plate" style="width: 100%">
            <template slot-scope="scope">
              <div class="car_item">
                <div :style="{ color: '#5187E7' }">{{ scope.row.segment }}</div>
                <div class="car_co_view">Co.</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Safety" label="Trip Type" style="width: 100%">
            <template slot-scope="scope">
              <div>{{ scope.row.Safety }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Contract" label="From" style="width: 100%">
            <template slot-scope="scope">
              <div>{{ scope.row.Contract }}</div>
              <div style="font-size: 10px; color: #adadad; line-height: 10px">
                2020-12-12 12:23
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Driving_license"
            label="To"
            style="width: 100%"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.Driving_license }}</div>
              <div style="font-size: 10px; color: #adadad; line-height: 10px">
                2020-12-12 12:23
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="phoen" label="Price(RM)" style="width: 100%">
            <template slot-scope="scope">
              <div>{{ scope.row.phoen }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="earning" label="Tips(RM)" style="width: 100%">
            <template slot-scope="scope">
              <div @click="jumpTrips()">{{ scope.row.earning }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="paging-box">
        <div class="totality">{{ total }} entries in total</div>
        <el-pagination
          align="center"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      total: 0,
      tableData: [
        {
          name: '张三',
          id: 975848294574,
          gender: 'Maie',
          segment: 'Wise',
          Safety: '86.5',
          Contract: '2024.04.01',
          Driving_license: '1234567',
          phoen: '18681499210',
          trips: 1,
          earning: 400.5
        }
      ]
    }
  },
  methods: {
    jumpTrips() {
      this.$router.push({
        path: '/views/home/fleetadmin/trips',
        query: {
          row
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // this.orderListEvent();
    }
  }
}
</script>

<style scoped lang="scss">
.outer-table-box {
  width: 100%;
  height: 531px;
  overflow: hidden;
  border-radius: 14px 14px 14px 14px;
  background: #fff;
  border: 1px solid #eaeaea;
  overflow: hidden;

  ::v-deep .el-table__header-wrapper {
    font-size: 10px !important;
    border-top: 1px solid #ededed !important;
  }

  ::v-deep .el-table__body-wrapper {
    font-size: 12px !important;
  }

  ::v-deep .el-table__body td {
    padding: 9.9px 0 !important;
    color: black !important;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #fcfcfc !important;
    color: #000 !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #eaeaea !important; /* 添加边框 */
  }

  .car_co_view {
    width: 32px;
    height: auto;
    background: rgba(90, 187, 110, 0.1);
    border-radius: 40px;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    color: #5abb6e;
    border: 1px solid #5abb6e;
  }

  .car_item {
    display: flex; /* 设置容器为flex布局 */
    justify-content: left; /* 水平居中对齐子元素 */
    align-items: center; /* 垂直居中对齐子元素 */
  }
}

.title_layout {
  width: 100%;
  height: 32px;
  display: flex; /* 设置容器为flex布局 */
  justify-content: left; /* 水平居中对齐子元素 */
  align-items: center; /* 垂直居中对齐子元素 */
  .img_view {
    width: 30px;
    height: 30px;
  }

  .title {
    width: auto;
    height: 23px;
    font-size: 20px;
    color: #989898;
    align-items: center;
  }

  .title_one {
    width: auto;
    height: 23px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    align-items: center;
  }
}

.justify {
  margin-top: 12px;
  box-sizing: border-box;
  /* width: 756px; */
  padding: 18px 18px 15px 18px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
}

.header-box {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  .header-select-time {
    width: 102px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #eaeaea;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  .header-time {
    width: 97px;
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
}

.paging-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;

  .totality {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #a0a0a0;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  ::v-deep .el-pagination {
  }

  ::v-deep .el-pager li,
  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    border: 1px solid #eaeaea !important;
    background: #fff !important;
    border-radius: 4px;
    min-width: 28px !important;
    padding: 0px !important;
    margin-left: 4px;
  }

  ::v-deep .el-pager .active {
    text-decoration-line: underline !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent !important;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    color: #5187e7;
    text-align: center;
    font-style: normal;
    background: #fff !important;
  }
}
</style>
