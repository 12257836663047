<template>
  <div class="out_box">
    <div class="box-con">
      <div class="header">
        <div class="h-tit">Drivers</div>
        <div style="position: relative">
          <el-input
            type="text"
            class="input-search"
            size="large"
            placeholder="Search Company ID"
            clearable
            show-clear
            v-model="searchValCitizenIdentity"
            @keydown.native.enter="handleEnter"
          />
          <img
            src="../../../assets/img/icon_search.png"
            alt=""
            class="search-icon"
          />
        </div>
      </div>
    </div>

    <div class="outer-table-box">
      <el-table
        :data="tableData"
        :max-height="tableHeight"
      >
        <el-table-column
          prop="name"
          label="Name"
          width="auto"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <img
                class="driver-img"
                :src="scope.row.avatar ? scope.row.avatar : require('@/assets/img/default-avatar.jpg')"
                @error="handleImageError"
              />
              <div
                class="item-name-text"
                :style="{ color: '#5187E7' }"
              >
                {{ scope.row.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="Email"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.email }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="Phone number"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.mobile }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyId"
          label="Company ID"
          width="auto"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.citizenIdentity }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="vehiclePlate"
          label="Vehicle Plate#"
          width="140px"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.vehicles"
              :key="item.id"
            >
              {{ item.licensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="role"
          label="Role"
          width="130px"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.vehicles"
              :key="item.id"
            >
              {{ item.role }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="120px"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.vehicles"
              :key="item.id"
            >
              {{ item.state ? item.state : '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentEntity"
          label="Payment entity"
          width="140px"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.vehicles"
              :key="item.id"
            >
              {{ item.paymentEntity ? item.paymentEntity : '-' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="paging-box">
      <div class="totality">{{ total }} entries in total</div>
      <el-pagination
        align="center"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { driversList } from '@/api/fleetadmin/drivers'

export default {
  components: {},
  data() {
    return {
      searchValCitizenIdentity: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableHeight: null
    }
  },
  filters: {},
  created() { },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = window.innerHeight - 200
    })
    let windowHeight =
      document.documentElement.clientHeight || document.bodyclientHeight
    this.tableHeight = windowHeight - 200
    console.log('tableHeight:', this.tableHeight)
    this.driversListEvent()
  },
  methods: {
    // 图片加载报错显示默认头像
    handleImageError(event) {
      event.target.src = require('@/assets/img/default-avatar.jpg');
    },
    handleEnter(event) {
      console.log('handleEnter:', event)
      if (event.keyCode === 13) {
        this.driversListEvent()
      }
    },
    driversListEvent() {
      const params = {
        number: this.currentPage,
        size: this.pageSize,
        citizenIdentity: this.searchValCitizenIdentity
      }
      driversList(params).then((res) => {
        console.log('driversList:', res)
        this.tableData = res.data.results
        console.log('driversList tableData:', this.tableData)
        this.total = res.data.totalElements
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.driversListEvent()
    }
  }
}
</script>

<style scoped lang="scss">
.box-con {
  margin-top: 12px;
  box-sizing: border-box;
  /* width: 756px; */
  padding: 18px 18px 15px 18px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .h-tit {
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }

    .h-yellow {
      width: 87px;
      background: #ffc649;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 16px;
    }

    .input-search {
      width: 225px;
      height: 32px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: center;
      // 边框隐藏
      ::v-deep .el-input__inner {
        border: none;
        text-indent: 18px;
      }
    }

    ::v-deep .el-input__suffix {
      margin-top: -3px !important;
    }

    .search-icon {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 17px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.outer-table-box {
  width: 100%;
  // height: 531px;
  overflow: hidden;
  border-radius: 0 0 14px 14px;
  background: #fff;
  // ::v-deep .el-table__body-wrapper .vehicle-plate-column .cell {
  //   border-left: 2px solid #5187e7;
  // }
  .driver-img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
  }

  ::v-deep .el-table__header-wrapper {
    font-size: 10px !important;
    border-top: 1px solid #ededed !important;
  }

  ::v-deep .el-table__body-wrapper {
    font-size: 12px !important;
  }

  ::v-deep .el-table__body td {
    padding: 9.9px 0 !important;
    color: black !important;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #fcfcfc !important;
    color: #000 !important;
  }

  ::v-deep .el-table th.el-table__cell:nth-child(9) {
    background: #fcfcfc !important;
    color: #000 !important;
    border-left: 1px solid #e8e8e8;
  }

  ::v-deep .el-table__body td:nth-child(9) {
    padding: 9.9px 0 !important;
    color: black !important;
    border-left: 1px solid #e8e8e8;
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #eaeaea !important;
  }
}

.paging-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;

  .totality {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #a0a0a0;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  ::v-deep .el-pagination {
  }

  ::v-deep .el-pager li,
  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    border: 1px solid #eaeaea !important;
    background: #fff !important;
    border-radius: 4px;
    min-width: 28px !important;
    padding: 0px !important;
    margin-left: 4px;
  }

  ::v-deep .el-pager .active {
    text-decoration-line: underline !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent !important;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    color: #5187e7;
    text-align: center;
    font-style: normal;
    background: #fff !important;
  }
}
</style>
