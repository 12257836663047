<template>
  <div class="out_box">
    <div class="box-con">
      <div class="header">
        <div class="h-tit">Vehicles</div>
        <div style="position: relative">
          <el-input
            type="text"
            class="input-search"
            size="large"
            placeholder="Search Plate Number"
            clearable
            show-clear
            v-model="searchValLicensePlate"
            @keydown.native.enter="handleEnter"
          />
          <img
            src="../../../assets/img/icon_search.png"
            alt=""
            class="search-icon"
          />
        </div>
      </div>
    </div>

    <div class="outer-table-box">
      <el-table :data="tableData" :max-height="tableHeight">
        <el-table-column label="Plate number" width="auto" prop="licensePlate">
          <template slot-scope="scope">
            <div style="color: #5187e7">
              {{ scope.row.licensePlate ? scope.row.licensePlate : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="type.name" label="Category" width="auto">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.type && scope.row.type.name
                  ? scope.row.type.name
                  : '-'
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="maker" label="Maker" width="auto">
          <template slot-scope="scope">
            <div>
              {{ scope.row.maker ? scope.row.maker : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="model" label="Model" width="auto">
          <template slot-scope="scope">
            <div>
              {{ scope.row.model ? scope.row.model : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="purchaseYear" label="Year" width="auto">
          <template slot-scope="scope">
            <div>
              {{ scope.row.purchaseYear ? scope.row.purchaseYear : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="purchasePrice" label="Cost" width="auto">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.purchasePrice ? scope.row.purchasePrice + ' MYR' : '-'
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="paging-box">
      <div class="totality">{{ total }} entries in total</div>
      <el-pagination
        align="center"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { vehiclesList } from '@/api/fleetadmin/vehicles'

export default {
  components: {},
  data() {
    return {
      searchValLicensePlate: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableHeight: ''
    }
  },
  filters: {},
  created() {},
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = window.innerHeight - 200
    })
    let windowHeight =
      document.documentElement.clientHeight || document.bodyclientHeight
    this.tableHeight = windowHeight - 200
    console.log('tableHeight:', this.tableHeight)
    this.vehiclesListEvent()
  },
  methods: {
    /** 搜索车牌号 */
    handleEnter(event) {
      console.log('handleEnter:', event)
      if (event.keyCode === 13) {
        this.vehiclesListEvent()
      }
    },
    /** 车辆列表 */
    vehiclesListEvent() {
      const params = {
        number: this.currentPage,
        size: this.pageSize,
        licensePlate: this.searchValLicensePlate
      }
      vehiclesList(params).then((res) => {
        console.log('vehiclesList:', res)
        this.tableData = res.data.results
        console.log('tripList tableData:', this.tableData)
        this.total = res.data.totalElements
      })
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.currentPage = val
      this.vehiclesListEvent()
    }
  }
}
</script>

<style scoped lang="scss">
.box-con {
  margin-top: 12px;
  height: 62px;
  box-sizing: border-box;
  /* width: 756px; */
  padding: 18px 18px 15px 18px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;

  .header {
    display: flex;
    justify-content: space-between;

    ::v-deep .el-input__suffix {
      position: absolute;
      top: -4px;
    }

    .h-tit {
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }

    .input-search {
      width: 225px;
      height: 32px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    /* 隐藏 el-input 边框 */
    ::v-deep .el-input__inner {
      border: none !important;
      box-shadow: none !important;
      text-indent: 18px !important;
    }

    /* 获取焦点时不显示边框颜色 */
    ::v-deep .el-input__inner:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .search-icon {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 17px;
      height: 16px;
      cursor: pointer;
    }
  }

  .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ededed;

    .nav-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-weight: 400;

      div {
        margin-right: 40px;
      }

      div:last-child {
        margin-right: 0;
      }

      .is-active {
        padding-bottom: 6px;
        font-weight: 600;
        border-bottom: 3px solid #ffc649;
      }
    }
  }

  .car-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    cursor: pointer;
  }

  .car-list-con {
    display: flex;
  }

  .car-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: 400;

    div {
      width: 88px;
      height: 32px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      color: #000;
      text-align: center;
      background: #fff;
      border-radius: 16px;
      border: 1px solid #dddddd;
    }

    // div:last-child {
    //   margin-right: 0;
    // }
    .is-active2 {
      background: rgb(255 198 73 / 30%);
      border: 1px solid #ffc649;
    }
  }

  .select-box {
    display: flex;
    align-items: center;
    margin-left: 15px;

    .selected {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 4px 0 14px;
      vertical-align: middle;
    }

    ::v-deep span {
      vertical-align: middle;
      // font-weight: 300;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 12px !important;
      color: #000000;
      text-align: center;
      font-style: normal;
    }

    /* 自定义 Element UI 复选框样式 */
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #000;
      background-color: #000;
    }

    ::v-deep .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
      border-color: #dcdfe6;
      background-color: #fff;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
      border-color: #fff;
    }

    ::v-deep .el-checkbox__label {
      color: #000;
    }
  }
}

.outer-table-box {
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 14px 14px;
  background: #fff;

  ::v-deep .el-table__header-wrapper {
    font-size: 10px !important;
    border-top: 1px solid #ededed !important;
  }

  ::v-deep .el-table__body-wrapper {
    font-size: 12px !important;
  }

  ::v-deep .el-table__body td {
    padding: 9.9px 0 !important;
    color: black !important;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #fcfcfc !important;
    color: #000 !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #eaeaea !important; /* 添加边框 */
  }

  // page-sizes选择器
}

.paging-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  height: 34px;

  .totality {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #a0a0a0;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }

  ::v-deep .el-pagination {
  }

  ::v-deep .el-pager li,
  ::v-deep .btn-next,
  ::v-deep .btn-prev {
    border: 1px solid #eaeaea !important;
    background: #fff !important;
    border-radius: 4px;
    min-width: 28px !important;
    padding: 0px !important;
    margin-left: 4px;
  }

  ::v-deep .el-pager .active {
    text-decoration-line: underline !important;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent !important;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    color: #5187e7;
    text-align: center;
    font-style: normal;
    background: #fff !important;
  }
}
</style>
