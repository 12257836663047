<template>
  <div>
    <el-button class="hide_btnstyle" type="text">
      <div class="date_picker">
        <img class="date_icon" src="../assets/img/icon_date_picker.png"/>
        <span class="date_str">{{ dateShow }}</span>
      </div>
      <el-date-picker
          @change="dateChange"
          v-model="dateDay"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Pick a date">
      </el-date-picker>
    </el-button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {},
  data() {
    return {
      dateDay: '',
      dateShow: ''
    };
  },
  mounted() {
    this.dateShow = this.initializeCurrentDate()
  },
  methods: {
    initializeCurrentDate() {
      return moment().format("MMM Do, YYYY")
    },
    dateChange(e) {
      this.dateShow = moment(e).format("MMM Do, YYYY")
      this.$emit('dateChange', e)
    },
  },
};
</script>

<style scoped lang="scss">
.hide_btnstyle {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  position: relative !important;
}

.hide_btnstyle .el-date-editor {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.date_picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 18px;
  border: 1px solid #EAEAEA;

  .date_icon {
    width: 20px;
    height: 20px;
  }

  .date_str {
    margin-left: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-align: center;
    font-style: normal;
  }
}
</style>
