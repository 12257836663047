import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login'
import home from '@/views/home/home.vue'
import trips from '@/views/home/trips/index'
import tripList from '@/views/home/trips/tripList.vue'

import drivers from '@/views/home/fleetadmin/drivers'
import vehicles from '@/views/home/fleetadmin/vehicles'
import fleetTrips from '@/views/home/fleetadmin/trips'
import hashimBriscam from '@/views/home/fleetadmin/hashimBriscam'

import employee from '@/views/home/employee/index.vue'

import fleetSafety from '@/views/home/risksafety/fleetSafety'
import emergencyMonitor from '@/views/home/tracking/emergencyMonitor'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: 'ALM'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    redirect: '/home/trips/tripList',
    children: [
      {
        path: '/home/trips/index',
        name: 'trips',
        component: trips,
        meta: {
          title: 'ALM'
        }
      },
      {
        path: '/home/trips/tripList',
        name: 'tripList',
        component: tripList,
        meta: {
          title: 'ALM'
        }
      },
      {
        path: '/home/employee/index',
        name: 'employee',
        component: employee
      },
      {
        path: '/home/risksafety/fleetSafety',
        name: 'fleetSafety',
        component: fleetSafety
      },
      {
        path: '/home/tracking/emergencyMonitor',
        name: 'emergencyMonitor',
        component: emergencyMonitor
      },
      {
        path: '/home/fleetadmin/drivers',
        name: 'drivers',
        component: drivers
      },
      {
        path: '/home/fleetadmin/vehicles',
        name: 'vehicles',
        component: vehicles
      },
      {
        path: '/home/fleetadmin/trips',
        name: 'fleetTrips',
        component: fleetTrips
      },
      {
        path: '/home/fleetadmin/hashimBriscam',
        name: 'hashimBriscam',
        component: hashimBriscam
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.afterEach((to, from) => {
  document.title = to.meta.title || ''
})

export default router
