<template>
  <div class="out_box">
    <div class="header-box">
      <div class="header-time">Jan 16, 2024</div>
      <div class="header-select-time container">
        Select Date <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="body-box-one">
      <!-- Orders -->
      <div class="orders-box">
        <div class="orders-head-text-box">Orders</div>
        <div class="orders-charts-box">
          <div class="orders-charts" id="order-chart"></div>
        </div>
        <div class="orders-final-report-box">
          <div class="orders-inbound">Inbound</div>
          <div class="inbound-outbound-ratio">
            <div class="inbound-box">
              <span>134 (40%)</span>
            </div>
            <div class="outbound-box">
              <span>201 (60%)</span>
            </div>
          </div>
          <div class="orders-outbound">Outbound</div>
        </div>
      </div>
      <!-- Vehicle type -->
      <div class="vehicle-type-box">
        <div class="orders-head-text-box">Vehicle type</div>
        <div class="vehicle-type-charts-box">
          <div class="vehicle-type-charts" id="vehicle-type-chart"></div>
        </div>
      </div>
      <div class="drivers-vehicles-box">
        <div class="drivers-box">
          <div class="orders-head-text-box">Drivers</div>
          <div class="drivers-content-box">
            <div class="drivers-head-content">
              <div>200</div>
              <div>+25%</div>
            </div>
            <div class="drivers-content">
              <div class="drivers-content-title">
                <div>Distance</div>
                <div>Time</div>
                <div>Waiting</div>
              </div>
              <div class="drivers-content-data">
                <div>875km (+23%)</div>
                <div>18hr (+23%)</div>
                <div>10hr (+23%)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="drivers-box">
          <div class="orders-head-text-box">Vehicles</div>
          <div class="drivers-content-box">
            <div class="drivers-head-content">
              <div>180</div>
              <div>+20%</div>
            </div>
            <div class="drivers-content">
              <div class="drivers-content-title">
                <div>Available</div>
                <div>Off</div>
                <div>Service</div>
              </div>
              <div class="drivers-content-data">
                <div>35 (+23%)</div>
                <div>18 (+23%)</div>
                <div>35 (+23%)</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="vehicles-box">
          <div class="trips-head-text-box">Vehicles</div>
        </div> -->
      </div>
    </div>
    <div class="body-box-two">
      <!-- Payment -->
      <div class="payment-box">
        <div class="orders-head-text-box">Payment</div>
        <div class="payment-charts-box">
          <div class="payment-charts" id="payment-chart"></div>
        </div>
      </div>
      <div class="channel-box">
        <div class="orders-head-text-box">Channel</div>
      </div>
      <div class="trends-box">
        <div class="orders-head-text-box">Trends</div>
      </div>
      <div class="exceptions-box">
        <div class="orders-head-text-box">Exceptions</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  components: {},
  data() {
    return {};
  },
  filters: {},
  created() {},
  mounted() {
    this.orderChartsEvent();
    this.vehicleTypeChartsEvent();
    this.paymentChartsEvent();
  },
  methods: {
    /**Payment 图*/
    paymentChartsEvent() {
      const chart = echarts.init(document.getElementById("payment-chart"));

      // 定义图表配置项
      const options = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "10%",
          itemWidth: 10, // 设置图例每个项的宽度为20
          itemHeight: 10, // 设置图例每个项的高度为10
          textStyle: {
            fontSize: 10, // 设置字体大小为14px
          },
          orient: "vertical",
          formatter: function (name) {
            return "\n" + name + "\n"; // 在图例文字后面添加数字，这里假设为固定的 100
          },
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
          },
        ],
      };

      // 渲染图表
      chart.setOption(options);
    },
    /**Vehicle type图 */
    vehicleTypeChartsEvent() {
      const chart = echarts.init(document.getElementById("vehicle-type-chart"));
      const options = {
        tooltip: {
          show: false,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: true, // 设置图例显示
          data: ["Available", "Deployed"], // 设置图例的数据
          textStyle: {
            color: "#A69FAD", // 设置图例文本颜色
          },
          itemWidth: 0, // 设置图例项的宽度为0，隐藏方块
          itemHeight: 0, // 设置图例项的高度为0，隐藏方块
          padding: [15, 1, 5, 0], // 设置图例的内边距 [上, 右, 下, 左]
          itemGap: 20, // 设置图例项之间的间距
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "-5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            show: false,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true, // 显示分界线
              lineStyle: {
                color: "#aaa", // 设置分界线颜色
                width: 1, // 设置分界线宽度
              },
            },
            axisLabel: {
              fontSize: 10,
              interval: 0,
              color: "black", // 设置轴标签文字颜色
              align: "right", // 设置轴标签文字左对齐
              formatter: function (value) {
                return value.length > 10
                  ? value.substring(0, 10) + "\n" + value.substring(10)
                  : value;
              },
            },
            data: ["Budget", "Premier", "Executive Premier", "Family"],
            splitLine: {
              show: false,
            },
          },
        ],
        color: ["#BABFC7", "#E5E7EA"], // 设置系列的颜色
        tooltip: {
          show: true, // 设置提示框显示
          trigger: "axis", // 设置触发类型为坐标轴轴线触发
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return (
              params[0].seriesName +
              ": " +
              params[0].value +
              "<br>" +
              params[1].seriesName +
              ": " +
              params[1].value
            );
          },
        },
        series: [
          {
            name: "Available", // 修改第一个系列的名称
            type: "bar",
            stack: "总量",
            label: {
              show: false,
              color: "white",
            },
            itemStyle: {
              color: "#BABFC7",
            },
            data: [-740, -402, -241, -300],
          },
          {
            name: "Deployed", // 修改第二个系列的名称
            type: "bar",
            stack: "总量",
            z: 10,
            label: {
              show: false,
              color: "white",
              position: "insideLeft",
              distance: -11,
            },
            itemStyle: {
              color: "#E5E7EA",
            },
            data: [300, 702, 141, 200],
          },
        ],
      };
      chart.setOption(options);
    },
    /** 订单图表*/
    orderChartsEvent() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("order-chart"));

      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          itemWidth: 10, // 设置图例每个项的宽度为20
          itemHeight: 10, // 设置图例每个项的高度为10
          textStyle: {
            fontSize: 10, // 设置字体大小为14px
          },
        },
        grid: {
          left: "15%",
          right: "0%",
          top: "0%",
          bottom: "0%",
        },
        graphic: [
          {
            type: "text",
            left: "center",
            top: "90",
            style: {
              rich: {
                a: {
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "red",
                },
                b: {
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "blue",
                  lineHeight: 30, // 设置行高为30
                },
              },
              text: "{a|330} \n {b|↑+20%}", // 使用富文本标签的语法定义不同样式的文本
              textAlign: "center",
              fill: "#000",
            },
          },
        ],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["50%", "85%"],
            center: ["50%", "45%"], // 设置饼图中心位置为图表中心
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: false, // 默认不显示标签
              position: "inside", // 设置标签位置为内部，即中间位置
              fontSize: 9,
              fontWeight: "bold",
              color: "#000",
              formatter: function (params) {
                if (params.data) {
                  return params.name + "\n" + params.value;
                }
                return "";
              },
              emphasis: {
                show: false, // 鼠标悬停时显示标签
                position: "inside", // 设置标签位置为内部，即中间位置
                fontSize: 9,
                fontWeight: "bold",
                color: "#000",
                formatter: function (params) {
                  if (params.data) {
                    return params.name + "\n" + params.value;
                  }
                  return "";
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "In progress" },
              { value: 735, name: "To pick-up" },
              { value: 580, name: "Completed" },
              { value: 484, name: "Exception" },
              { value: 300, name: "Cancelled" },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.header-box {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  .header-select-time {
    width: 102px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #eaeaea;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }
  .header-time {
    width: 97px;
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
}
.orders-head-text-box {
  width: 150px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  font-style: normal;
  margin: 12px 0 0 16px;
}
.body-box-one {
  height: 358px;
  width: 100%;
  display: flex;
  margin-bottom: 11px;
  justify-content: space-between;
  .orders-box {
    width: 448px;
    height: 358px;
    background: #ffffff;
    border-radius: 8px;

    .orders-charts-box {
      width: 378px;
      height: 231px;
      margin: 5px auto 28px auto;
      .orders-charts {
        width: 100%;
        height: 100%;
      }
    }
    .orders-final-report-box {
      width: 400px;
      height: 36px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .inbound-outbound-ratio {
        width: 268px;
        height: 36px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        text-align: right;
        font-style: normal;
        .inbound-box {
          height: 36px;
          width: 40%;
          background: #5071e6;
          display: flex;
          align-items: center;
          span {
            margin-left: 8px;
          }
        }
        .outbound-box {
          height: 36px;
          width: 60%;
          background: #2fd990;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            margin-right: 8px;
          }
        }
      }
      .orders-inbound,
      .orders-outbound {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 17px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .vehicle-type-box {
    width: 218px;
    height: 358px;
    background: #ffffff;
    border-radius: 8px;
    .vehicle-type-charts-box {
      width: 180px;
      height: 296px;
      margin: 12px auto 0 auto;
      border: 1px solid #e6e6e6;
      .vehicle-type-charts {
        width: 100%;
        height: 100%;
      }
    }
  }
  .drivers-vehicles-box {
    width: 218px;
    height: 358px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .drivers-box {
      .drivers-content-box {
        width: 181px;
        height: 117px;
        margin: 10px auto;
        .drivers-head-content {
          // width: 91px;
          height: 33px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          div:nth-child(1) {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            line-height: 33px;
            text-align: left;
            font-style: normal;
          }
          div:nth-child(2) {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            margin-left: 8px;
          }
        }
        .drivers-content {
          display: flex;
          justify-content: space-between;
          .drivers-content-title {
            width: 58px;
            height: 76px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #9c9c9c;
              line-height: 20px;
              text-align: right;
              font-style: normal;
            }
          }
          .drivers-content-data {
            width: 98px;
            height: 76px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
          }
        }
      }
    }
  }
  .drivers-vehicles-box > div {
    width: 218px;
    height: 174px;
    background: #ffffff;
    border-radius: 8px;
  }
}
.body-box-two > div {
  width: 218px;
  height: 358px;
  background: #ffffff;
  border-radius: 8px;
}
.body-box-two {
  height: 358px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .payment-box {
    .payment-charts-box {
      width: 172px;
      height: 291px;
      border: 1px solid #ebebeb;
      .payment-charts {
        width: 100%;
        height: 100%;
      }
    }
  }
  .channel-box {
  }
  .trends-box {
  }
  .exceptions-box {
  }
}
</style>
