<template>
  <div class="out-boxs">
    <div class="login-left">
      <img
        class="logo-img"
        src="../../assets/img/login_logo.png"
        alt=""
        srcset=""
      />
      <!-- 正常登录 -->
      <div
        class="login-box"
        v-if="loginEventShow == 0"
      >
        <div class="welcom">Welcome to ALM Fleet Mgmt</div>
        <div class="login-ipt-box">
          <div class="user-name-box">
            <input
              type="text"
              v-model="form.name"
              placeholder="E-mail address"
            />
          </div>
          <div class="password-box">
            <input
              :type="passwordShow ? 'text' : 'password'"
              v-model="form.password"
              placeholder="Password"
            />
            <img
              v-if="passwordShow"
              @click="passwordShow = !passwordShow"
              src="../../assets/img/password_show.png"
              alt=""
              srcset=""
            />
            <img
              v-else
              @click="passwordShow = !passwordShow"
              src="../../assets/img/password_no_show.png"
              alt=""
              srcset=""
            />
          </div>
          <div
            class="forget-pwd"
            @click="loginEventShow = 1"
          >
            Forget password?
          </div>
        </div>
      </div>
      <!-- 找回密码 -- 邮箱验证 -->
      <div
        class="login-box"
        v-else-if="loginEventShow == 1"
      >
        <div class="reset-password-header">
          <img
            @click="loginEventShowEvent(0)"
            src="../../assets/img/back.png"
            alt=""
          />Reset password
        </div>
        <div class="reset-password-tips">
          Please enter your email address to get a verification code, and enter
          the verification code below
        </div>
        <div class="reset-password-ipt-box">
          <div
            class="user-name-box"
            :style="nameStyle ? 'border-bottom: 2px solid #E93E25;' : ''"
          >
            <input
              type="text"
              v-model="form.emailName"
              placeholder="E-mail address"
              @input="emailNameInput"
            />
            <div
              v-if="!countDownShow"
              :style="form.emailName === '' ? 'background: #ccc;' : ''"
              class="verification-code container"
              @click="sendEmailCode"
            >
              Send
            </div>
            <div
              v-else
              class="verification-code-countdown container"
            >
              {{ countdown }}S
            </div>

            <div
              class="name-error-text"
              v-if="nameStyle"
            >
              The email address format is incorrect
            </div>
          </div>
          <div
            class="user-code-box"
            :style="codeStyle ? 'border-bottom: 2px solid #E93E25;' : ''"
          >
            <input
              type="text"
              v-model="form.emailCode"
              placeholder="Verification code"
            />
          </div>
          <div
            class="code-error-text"
            v-if="codeStyle"
          >
            Incorrect verification code
          </div>
        </div>
      </div>
      <!-- 新密码设置 -->
      <div
        class="login-box"
        v-else-if="loginEventShow == 2"
      >
        <div class="reset-password-header">
          <img
            @click="loginEventShow = 1"
            src="../../assets/img/back.png"
            alt=""
          />Create new password
        </div>
        <div class="reset-password-tips">
          The password should be minimum 8 digits, must contain numbers and
          letters, support ,.!_?symbol
        </div>
        <div class="reset-password-ipt-box">
          <div
            class="user-name-box"
            :style="oldPasswordStyle ? 'border-bottom: 2px solid #E93E25;' : ''"
          >
            <input
              type="text"
              v-model="form.oldPassword"
              placeholder="New password"
            />
            <div
              class="name-error-text"
              v-if="oldPasswordStyle"
            >
              The password must contain both numbers and letters
            </div>
          </div>
          <div
            class="user-code-box"
            :style="newPasswordStyle ? 'border-bottom: 2px solid #E93E25;' : ''"
          >
            <input
              type="text"
              v-model="form.newPassword"
              placeholder="Confirm new password"
            />
          </div>
          <div
            class="code-error-text"
            v-if="newPasswordStyle"
          >
            Two passwords do not match!
          </div>
        </div>
      </div>
      <el-button
        v-if="loginEventShow == 0"
        type="primary"
        @click="toLogin"
      >Log in
      </el-button>
      <el-button
        v-else-if="loginEventShow == 1"
        type="primary"
        @click="confirmResetPassword"
      >Confirm
      </el-button>
      <el-button
        v-else-if="loginEventShow == 2"
        type="primary"
        @click="confirmPassword"
      >Confirm
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  login,
  employeesProfiles,
  codesSends,
  codesVerifications,
  passwordsResets,
  accountVerification
} from '@/api/login/login'
import { saveLocalStorage, getLocalStorage } from '@/utils/localStorage'
import { MD5 } from 'crypto-js'
import store from '@/store'

export default {
  data() {
    return {
      oldPasswordStyle: false,
      newPasswordStyle: false,
      nameStyle: false,
      codeStyle: false,
      loginEventShow: 0, //0 正常登录  1 邮箱验证  2 新密码设置
      passwordShow: false,
      // phone: "123",
      // labelPosition: "top",
      form: {
        name: '',
        password: '',
        emailName: '',
        emailCode: '',
        oldPassword: '',
        newPassword: ''
      },
      countdown: 60, // 初始倒计时时间
      timer: null, // 定时器对象
      countDownShow: false // 是否显示倒计时
    }
  },
  computed: {},
  created() {
    if (getLocalStorage('token')) {
      this.$router.push({ path: '/home' })
    }
  },
  methods: {
    /**重置密码提交  */
    confirmPassword() {
      const { oldPassword, newPassword } = this.form
      if (oldPassword === '' || newPassword === '') return
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d.！_？]{8,}$/
      if (passwordRegex.test(oldPassword)) {
        this.oldPasswordStyle = false
        if (oldPassword === newPassword) {
          this.newPasswordStyle = false
          // console.log("密码相同");
        } else {
          this.newPasswordStyle = true
          // console.log("新密码与旧密码不同");
          return
        }
        // console.log("密码校验通过", this.form);
        const params = {
          email: this.form.emailName,
          verificationCode: this.form.emailCode,
          password: MD5(this.form.oldPassword).toString(),
          confirmPassword: MD5(this.form.newPassword).toString()
        }
        passwordsResets(params).then((r) => {
          this.$message({
            message: 'Password reset succeeded',
            type: 'success'
          })
          this.loginEventShow = 0
          for (let key in this.form) {
            this.form[key] = ''
          }
        })
      } else {
        this.oldPasswordStyle = true
        // console.log("旧密码格式不符合要求");
      }
    },
    /**校验验证码 ----下一步 */
    confirmResetPassword() {
      if (this.form.emailCode == '' || this.form.emailName == '') return
      console.log(this.form.emailCode, this.form.emailName)
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailRegex.test(this.form.emailName)
      if (isValidEmail) {
        // console.log("邮箱格式正确");
        this.nameStyle = false
        const params = {
          verificationCode: this.form.emailCode,
          email: this.form.emailName,
          type: 'RESET_PASSWORD',
          userType: 'ADMIN'
          // clientId: store.state.clientId,
          // clientSecret: store.state.clientSecret,
        }
        codesVerifications(params)
          .then((r) => {
            // console.log("校验验证码", r);
            this.codeStyle = false
            this.loginEventShow = 2
          })
          .catch((err) => {
            // console.log("校验验证码", err);
            this.codeStyle = true
          })
      } else {
        this.nameStyle = true
        // console.log("邮箱格式不正确");
      }
    },
    loginEventShowEvent(e) {
      if (e == 0) {
        this.loginEventShow = e
        this.form.emailName = ''
        this.form.emailCode = ''
      }
    },
    /** 校验邮箱唯一性 */
    accountVerificationEvent() {
      return new Promise((resolve, reject) => {
        var data = {
          email: this.form.emailName,
        }
        accountVerification(data).then((val) => {
          resolve()
        }).catch(err => {
          reject()
        })
      })
    },
    /**发送邮件 */
    async sendEmailCode() {
      if (
        this.form.emailName == '' ||
        this.form.emailName == null ||
        this.form.emailName.length < 0
      )
        return
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailRegex.test(this.form.emailName)
      if (isValidEmail) {
        // console.log("邮箱格式正确");
        this.nameStyle = false
        await this.accountVerificationEvent()
        const params = {
          email: this.form.emailName,
          type: 'RESET_PASSWORD',
          userType: 'ADMIN'
        }
        codesSends(params).then((res) => {
          // console.log("发送邮件", res);
        })

        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countDownShow = true
            this.countdown--
          } else {
            clearInterval(this.timer)
            this.countDownShow = false
            this.countdown = 60
            this.timer = null
            // console.log("倒计时结束");
          }
        }, 1000)
      } else {
        this.nameStyle = true
        // console.log("邮箱格式不正确");
      }
    },
    emailNameInput(e) {
      // console.log("e", e.target.value);
      // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // const isValidEmail = emailRegex.test(e.target.value);
      // if (isValidEmail) {
      //   console.log("邮箱格式正确");
      //   this.nameStyle = false;
      // } else {
      //   this.nameStyle = true;
      //   console.log("邮箱格式不正确");
      // }
    },
    toLogin() {
      if (this.form.password === '' || this.form.name === '') {
        this.$message.error('Please enter your Email and password')
      } else {
        const params = {
          username: this.form.name,
          password: MD5(this.form.password).toString(),
          clientId: store.state.clientId,
          clientSecret: store.state.clientSecret,
          grantType: 'password'
        }
        login(params).then((res) => {
          saveLocalStorage('token', res.data.token)
          employeesProfiles().then((r) => {
            saveLocalStorage('personageInfo', JSON.stringify(r.data))
            this.$router.push('/home')
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.out-boxs {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: url('@/assets/img/login_bc.png') no-repeat;

  .login-left {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 382px;
    height: 575px;
    background: #fff;
    border-radius: 8px;
    transform: translate(-50%, -50%);

    .logo-img {
      width: 48px;
      height: 56px;
      margin: 32px auto 30px auto;
      display: block;
    }

    .login-box {
      width: 100%;
      height: 345px;
      // 找回密码
      .reset-password-header {
        width: 298px;
        height: 29px;
        margin: 32px auto 12px auto;
        display: flex;
        align-items: center;
        font-family: SFPro, SFPro;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        text-align: left;
        font-style: normal;

        img {
          width: 10px;
          height: 16px;
          margin-right: 9px;
          cursor: pointer;
        }
      }

      .reset-password-tips {
        width: 298px;
        height: 28px;
        font-family: SFPro, SFPro;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 14px;
        font-style: normal;
        margin: 0 auto 48px auto;
      }

      .reset-password-ipt-box {
        width: 298px;
        height: 144px;
        margin: 0 auto;

        input {
          border: none;
        }

        .user-name-box {
          margin-bottom: 32px;
        }

        .user-code-box {
          margin-bottom: 6px;
        }

        .user-name-box input:focus,
        .user-code-box input:focus {
          outline: none;
        }

        .user-code-box {
          width: 298px;
          height: 39px;
          border-bottom: 2px solid #eaeaea;
          position: relative;

          input {
            width: 298px;
            height: 22px;
            font-size: 16px;
            // font-family: PingFangSC;
            font-weight: 500;
            color: #000;
          }
        }

        .user-name-box {
          width: 298px;
          height: 39px;
          border-bottom: 2px solid #eaeaea;
          position: relative;

          .name-error-text {
            height: 17px;
            width: 298px;
            margin: 0 auto;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #e93e25;
            text-align: left;
            font-style: normal;
            position: absolute;
            left: 0;
            bottom: -26px;
          }

          .verification-code {
            width: 54px;
            height: 32px;
            background: #000000;
            border-radius: 4px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
            text-align: right;
            position: absolute;
            font-style: normal;
            right: 0;
            top: -5px;
            cursor: pointer;
          }

          .verification-code-countdown {
            width: 54px;
            height: 32px;
            background: #ccc;
            border-radius: 4px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
            text-align: right;
            position: absolute;
            font-style: normal;
            right: 0;
            top: -5px;
            cursor: pointer;
          }

          input {
            width: 298px;
            height: 22px;
            font-size: 16px;
            // font-family: PingFangSC;
            font-weight: 500;
            color: #000;
          }
        }
      }

      .code-error-text {
        height: 17px;
        width: 298px;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #e93e25;
        text-align: left;
        font-style: normal;
      }

      // 正常登录
      .welcom {
        width: 266px;
        margin: 0 auto;
        // height: 119px;
        font-size: 32px;
        // font-family: DINAlternate;
        font-weight: bold;
        line-height: 37px;
        color: #000;
        text-align: center;
      }

      .login-ipt-box {
        width: 298px;
        height: 139px;
        // border: 1px solid;
        margin: 50px auto 84px;

        input {
          border: none;
        }

        .user-name-box {
          margin-bottom: 32px;
        }

        .user-name-box input:focus,
        .password-box input:focus {
          outline: none;
        }

        .user-name-box,
        .password-box {
          width: 298px;
          height: 39px;
          border-bottom: 2px solid #eaeaea;
          position: relative;

          img {
            position: absolute;
            right: 0px;
            top: 5px;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          input {
            width: 298px;
            height: 22px;
            font-size: 16px;
            // font-family: PingFangSC;
            font-weight: 500;
            color: #000;
          }
        }

        .forget-pwd {
          margin-top: 12px;
          // width: 357px;
          // height: 27px;
          font-size: 12px;
          // font-family: PingFangSC;
          font-weight: 400;
          line-height: 27px;
          color: #acacac;
          text-align: right;
          cursor: pointer;
        }
      }
    }

    .el-button--primary {
      display: block;
      width: 298px;
      height: 60px;
      margin: 0 auto;
      font-size: 24px;
      font-weight: 500;
      line-height: 33px;
      color: white; /* 设置文字颜色为白色，以保证文字可见性 */
      background: #000;
      border-color: black;
      border-radius: 8px;
    }
  }
}
</style>

//
<style scoped lang="scss">
// .head {
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   .bottom-form {
//     flex: 1;
//     display: flex;
//     justify-content: center;
//     margin-top: 130.5px;
//     /*TODO 字体样式使用共用样式,并且指定不同平台下的字段*/
//     .el-form-item__label {
//       font-family: "PingFang SC";
//       font-style: normal;
//       font-size: 20px;
//       font-weight: 400;
//       color: #000000;
//     }
//     .el-input__inner {
//       width: 392.51px;
//       background-color: #ffffff;
//     }
//     .to-login-btn {
//       width: 392.51px;
//       margin-top: 25px;
//     }
//     .el-form-item__label {
//       margin-bottom: -4px;
//     }
//   }
// }
//
</style>
