import http from '../http'

// 员工账号列表
export const employeeList = (param) => http.get(`/api/employees`, param)

// 创建员工账号
export const createEmployee = (param) => http.post(`/api/employees`, param)

// 编辑员工账号
export const updateEmployee = (id, param) =>
  http.put(`/api/employees/${id}`, param)

// 删除员工账号
export const deleteEmployee = (id) => http.delete(`/api/employees/${id}`)

// 编辑员工账号状态
export const updateEmployeeState = (id, param) =>
  http.put(`/api/employees/${id}/states`, param)
