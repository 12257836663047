import http from '../http';
import {MD5} from "crypto-js";
import store from "../../store/index";

// 国家列表
export const countriesList = () => http.get(`/api/countries`)

// 员工简介-更新
export const staffProfilesUpd = (param) =>
    http.put(`/api/employees/profiles`, param)

// 员工账户密码-验证
export const passwordVerify = (param) =>
    http.get(`/api/employees/accounts/passwords/verifications`, param)

// 员工账户密码-更新
export const passwordUpd = (param) =>
    http.put(`/api/employees/accounts/passwords/changes`, param)
