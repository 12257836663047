<template>
  <el-dialog class="dialog-container" :visible="dialogVisible" @close="close">
    <!-- SLOT TITLE -->
    <div slot="title" class="dialog-title">
      <div class="title-name">{{ data ? 'Edit' : 'Add' }}</div>
    </div>

    <!-- ADD/EDIT ACCOUNT -->
    <div class="d_flex_column">
      <!--      <div class="divider" />-->
      <!--      <div class="w100 pdt10 pdb10 d_flex d_column_center">-->
      <!--        <strong class="w140px">Profile avator</strong>-->
      <!--        <div class="user_info_img">-->
      <!--          <img src="../../../assets/img/user_img.jpg" alt="ALM" />-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="divider" />
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w120px">Role</strong>
        <el-select
          class="w200px mgl4"
          v-model="value"
          placeholder="Please Select"
          @change="roleSelectChange"
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w140px">Name</strong>
        <el-input
          class="mgl10"
          v-model="name"
          placeholder="Please input username"
        ></el-input>
      </div>
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w200px">Phone number</strong>
        <el-select
          v-model="nationCode"
          slot="prepend"
          placeholder=""
          class="custom-select"
          :style="'background: #fff;'"
        >
          <el-option
            v-for="option in countriesListData"
            :key="option.id"
            :label="'+ ' + option.phoneCode"
            :value="option.phoneCode"
          ></el-option>
        </el-select>
        <el-input
          class="mgl10"
          v-model="mobile"
          placeholder="Please input phone number"
        ></el-input>
      </div>
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w140px">Account</strong>
        <el-input
          class="mgl10"
          v-model="email"
          placeholder="Please input email account address"
        ></el-input>
      </div>
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w140px">Password</strong>
        <el-input
          class="mgl10"
          v-model="password"
          placeholder="Please input account password"
          show-password
        ></el-input>
      </div>
    </div>

    <!-- SLOT FOOTER -->
    <span slot="footer">
      <el-button class="cancel" @click="dialogVisible = false"
        >Cancel</el-button
      >
      <el-button class="confirm" @click="confirm">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Message } from 'element-ui'
import { createEmployee, updateEmployee } from '@/api/employee'
import { MD5 } from 'crypto-js'
import { countriesList } from '@/api/head'

export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      data: undefined,
      countriesListData: '',
      nationCode: '60',
      roleOptions: [
        {
          value: '1',
          label: 'Operator'
        },
        {
          value: '2',
          label: 'Admin'
        }
      ],
      value: '',
      name: '',
      mobile: '',
      email: '',
      password: ''
    }
  },
  mounted() {
    this.dialogVisible = false
  },
  methods: {
    show(data) {
      this.dialogVisible = true
      this.data = data
      console.log('data:', this.data)
      if (this.data) {
        this.value = this.matchRoleValue(this.data.role, this.roleOptions)
        this.name = this.data.name
        this.nationCode = this.data.nationCode
        this.mobile = this.data.mobile
        this.email = this.data.email
        this.password = ''
      }
      countriesList().then((res) => {
        this.countriesListData = res.data.results
      })
    },
    matchRoleLabel(roleVal, roleOptions) {
      let role = undefined
      if (roleOptions) {
        role = roleOptions.filter((role) => role.value === roleVal)
      }
      return role && role.length === 1 && role[0].label
        ? role[0].label.toUpperCase()
        : ''
    },
    matchRoleValue(roleType, roleOptions) {
      let role = undefined
      if (roleOptions) {
        role = roleOptions.filter(
          (role) => role.label.toUpperCase() === roleType
        )
      }
      return role && role.length === 1 && role[0].value ? role[0].value : ''
    },
    reset() {
      this.value = ''
      this.name = ''
      this.nationCode = ''
      this.mobile = ''
      this.email = ''
      this.password = ''
    },
    close() {
      this.dialogVisible = false
      this.reset()
    },
    roleSelectChange(e) {
      console.log('roleSelectChange :', e)
    },
    confirm() {
      if (this.value === '' || this.value === undefined) {
        Message.info('Please select role')
        return
      }
      if (this.name === '' || this.name === undefined) {
        Message.info('Please input name')
        return
      }
      if (this.nationCode === '' || this.nationCode === undefined) {
        Message.info('Please select nationCode')
        return
      }
      if (this.mobile === '' || this.mobile === undefined) {
        Message.info('Please input mobile')
        return
      }
      if (this.email === '' || this.email === undefined) {
        Message.info('Please input email account')
        return
      }
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailRegex.test(this.email)
      if (!isValidEmail) {
        Message.info('The email account format is incorrect')
        return
      }
      // if (this.password === '' || this.password === undefined) {
      //   Message.info('Please input account password')
      //   return
      // }

      const role = this.matchRoleLabel(this.value, this.roleOptions)
      console.log('role:', role)

      if (this.data) {
        var params = {
          role: role,
          name: this.name,
          nationCode: this.nationCode,
          mobile: this.mobile,
          email: this.email
        }
        if (this.password) {
          params['password'] = MD5(this.password).toString()
        }
        console.log('update params:', params)
        updateEmployee(this.data.id, params)
          .then((res) => {
            console.log('update res:', res)
            this.$emit('edit')
            this.close()
          })
          .catch((exp) => {
            console.log('update exp:', exp)
            this.$emit('edit')
          })
      } else {
        var params = {
          role: role,
          name: this.name,
          nationCode: this.nationCode,
          mobile: this.mobile,
          email: this.email
        }
        if (this.password) {
          params['password'] = MD5(this.password).toString()
        }
        console.log('create params:', params)
        createEmployee(params)
          .then((res) => {
            console.log('create res:', res)
            this.$emit('edit')
            this.close()
          })
          .catch((exp) => {
            console.log('create exp:', exp)
            this.$emit('edit')
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-container {
  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .title-name {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      // &::before {
      //     width: 26px;
      //     height: 26px;
      //     content: "";
      //     background: url("../../assets/image/home/warn-icon.png")
      //         no-repeat;
      //     background-size: 100% 100%;
      //     margin-right: 10px;
      // }
    }

    .right-btn {
      height: 40px;
      cursor: pointer;
    }

    .right-btn .el-icon {
      margin-right: 10px;
      height: 100%;
    }

    .right-btn .el-icon:hover {
      color: #fff000;
    }
  }

  .cancel {
    width: 154px;
    height: 46px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: center;
    font-style: normal;
  }

  .confirm {
    width: 154px;
    height: 46px;
    background: #000000;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    font-style: normal;
  }

  .user_info_img {
    width: 68px;
    height: 68px;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
