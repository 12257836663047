<template>
  <el-dialog class="dialog-container" :visible="dialogVisible" @close="close">
    <!-- SLOT TITLE -->
    <div slot="title" class="dialog-title">
      <div class="title-name">Order No : {{ orderData.orderNo }}</div>
    </div>

    <!-- ORDER DETAIL INFO -->
    <div class="d_flex_column">
      <!-- ORDER BASIC INFO -->
      <div class="divider" />
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w120px">Trip</strong>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Order time</div>
        <div>{{ orderData.orderTime }}</div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Trip type</div>
        <div>{{ orderData.type ? orderData.type : '-' }}</div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Car type</div>
        <div>
          {{
            orderData
              ? orderData.vehicleType && orderData.vehicleType.name
                ? orderData.vehicleType.name
                : '-'
              : '-'
          }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Pick-up location</div>
        <div>
          {{
            orderData
              ? orderData.originLocation && orderData.originLocation.address
                ? orderData.originLocation.address
                : '-'
              : '-'
          }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Pick-up time</div>
        <div>
          {{
            orderData
              ? orderData.departureTime
                ? orderData.departureTime
                : '-'
              : '-'
          }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Drop-off location</div>
        <div>
          {{
            orderData
              ? orderData.destinationLocation &&
                orderData.destinationLocation.address
                ? orderData.destinationLocation.address
                : '-'
              : '-'
          }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Drop-off time</div>
        <div>
          {{
            orderData
              ? orderData.completeTime
                ? orderData.completeTime
                : '-'
              : '-'
          }}
        </div>
      </div>

      <!-- CUSTOMER BASIC INFO -->
      <div class="divider mgt16" />
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w120px">Customer</strong>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">E-mail</div>
        <div>
          {{
            orderData.customer && orderData.customer.email
              ? orderData.customer.email
              : '-'
          }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Phone</div>
        <div>
          {{
            orderData.customer && orderData.customer.mobile
              ? orderData.customer.mobile
              : '-'
          }}
        </div>
      </div>

      <!-- CHARGE BASIC INFO -->
      <div class="divider mgt16" />
      <div class="w100 mgt16 d_flex d_column_center">
        <strong class="w120px">Charge</strong>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Payment method</div>
        <div>{{ orderData.payChannel ? orderData.payChannel : '-' }}</div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Distance zone</div>
        <div v-if="orderData.fees">
          {{ getFeesbyType(orderData.fees, 'DISTANCE_ZONE') }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Late evening fee</div>
        <div v-if="orderData.fees">
          {{ getFeesbyType(orderData.fees, 'LATE_EVENING_FEE') }}
        </div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Discount</div>
        <div>-</div>
      </div>
      <div class="w100 mgt16 d_flex d_between">
        <div class="w120px">Total</div>
        <strong>{{
          orderData.totalAmount ? 'MYR ' + orderData.totalAmount : '-'
        }}</strong>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { orderTripReceiptsDetail } from '@/api/orders/orderList'

export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      id: undefined,
      orderData: {}
    }
  },
  mounted() {
    this.dialogVisible = false
  },
  methods: {
    show(id) {
      this.dialogVisible = true
      console.log('order ID:', id)
      this.getOrderTripReceiptsDetail(id)
    },
    close() {
      this.dialogVisible = false
    },
    async getOrderTripReceiptsDetail(id) {
      const params = { orderId: id }
      console.log('orderTripReceiptsDetail params:', params)
      await orderTripReceiptsDetail(params).then((res) => {
        console.log('orderTripReceiptsDetail res:', res.data)
        this.orderData = res.data
      })
    },
    getFeesbyType(fees, type) {
      let fee = undefined
      if (fees) {
        fee = fees.filter((fee) => fee.type === type)
      }
      // console.log('getFeesbyType fee:', fee)
      return fee && fee.length === 1 ? 'MYR ' + fee[0].amount : '-'
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-container {
  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .title-name {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      // &::before {
      //     width: 26px;
      //     height: 26px;
      //     content: "";
      //     background: url("../../assets/image/home/warn-icon.png")
      //         no-repeat;
      //     background-size: 100% 100%;
      //     margin-right: 10px;
      // }
    }

    .right-btn {
      height: 40px;
      cursor: pointer;
    }

    .right-btn .el-icon {
      margin-right: 10px;
      height: 100%;
    }

    .right-btn .el-icon:hover {
      color: #fff000;
    }
  }

  .cancel {
    width: 154px;
    height: 46px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: center;
    font-style: normal;
  }

  .confirm {
    width: 154px;
    height: 46px;
    background: #000000;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    font-style: normal;
  }

  .user_info_img {
    width: 68px;
    height: 68px;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
